import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import ContactCard from "./contactcard";
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init();
const ContactsWrap = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 2.5rem 0;
  background: var(--primary);
`;
const ContactsSection = styled.div`
  max-width: 1366px;
  margin: 0 auto;
  color: var(--primary);
  & h2 {
    color: #035561;
    margin-bottom: 2.5rem;
  }
  @media (max-width: 1365px) {
    width: 90%;
  }
  @media (max-width: 1024px) {
    box-sizing: border-box;
    width: 90%;
    flex-direction: column;
    padding: 0 1.25rem;
  }
`;
const Caption = styled.div`
  width: 50%;
  color: var(--text);
  font-size: 1.125rem;
  @media (max-width: 1024px) {
    width: 100%;
  }
`;
const ContactsCardSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 2rem;
  margin: 6.25rem 0;
  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;
function Contacts() {
  const { t } = useTranslation();
  return (
    <ContactsWrap id={"contacts"}>
      <ContactsSection>
        <h2>{t("tab5")}</h2>
        <ContactsCardSection>
          <ContactCard
            image={require("../assets/img/contacts/pin_icon.png")}
            title={t("sect5opt1")}
            caption={t("sect5opt1Txt")}
            href={
              "https://www.google.pt/maps/search/Rua+Dr.+Fern%C3%A3o+de+Ornelas+n%C2%BA56,+4%C2%BA+AC+9050-021+Funchal/@32.6489753,-16.9075309,17z/data=!3m1!4b1?hl=pt-PT"
            }
          />
          <ContactCard
            image={require("../assets/img/contacts/mail.png")}
            title={t("sect5opt2")}
            caption={t("sect5opt2Txt")}
            href={`mailto:${t("sect5opt2Txt")}`}
          />
          <ContactCard
            image={require("../assets/img/contacts/ring.png")}
            title={t("sect5opt3")}
            caption={t("sect5opt3Txt")}
            href={`tel:${t("sect5opt3Txt")}`}
          />
        </ContactsCardSection>
        <h2 data-aos="fade-up">Vipa Lab - Núcleo de I&D</h2>
        <Caption data-aos="fade-up">{t("contact2")}</Caption>
        <ContactsCardSection>
          <ContactCard
            image={require("../assets/img/contacts/pin_icon.png")}
            title={t("contact2opt")}
            caption={
              "Startup Madeira, Sala 11, Campus da Penteada, 9020-105, Funchal"
            }
            href={
              "https://www.google.pt/maps/search/Startup+Madeira,+Sala+11,+Campus+da+Penteada,+9020-105,+Funchal/@32.6483238,-16.9267234,13z/data=!3m1!4b1?hl=pt-PT"
            }
          />
          <ContactCard
            image={require("../assets/img/contacts/web.png")}
            title={"Website"}
            href={"https://www.vipalab.eu"}
            caption={"www.vipalab.eu"}
          />
        </ContactsCardSection>
      </ContactsSection>
    </ContactsWrap>
  );
}

export default Contacts;
