import React, { useEffect } from "react";
import styled from "styled-components";
import jQuery from "jquery";
import bg from "../assets/img/careers/careerbanner.png";
import { useTranslation } from "react-i18next";

const CareerWrap = styled.div`
  width: 100%;
  position: relative;
  top: 150px;
  min-height: 100vh;
  margin-bottom: 150px;
  @media (max-width: 1024px) {
    padding: 0 1.25rem;
    box-sizing: border-box;
  }
`;
const CareerSection = styled.div`
  width: 75%;
  height: 100%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2.5rem;
  padding: 6.25rem 0;
  @media (max-width: 1024px) {
    width: 100%;
    display: block;
  }
`;
const CareerHero = styled.div`
  position: relative;
  width: 75%;
  height: 500px;
  margin: 0 auto;
  background: linear-gradient(169.85deg, #5cc7cf 17.02%, #317180 149.42%);
  border-radius: 10px;
  overflow: hidden;
  &::after {
    position: absolute;
    left: 0;
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background-image: url(${bg});
    background-size: cover;
  }
  @media (max-width: 1024px) {
    width: 100%;
  }
`;
const Ship = styled.div.attrs((props) => ({ className: "ship" }))`
  content: "";
  width: 200px;
  height: 200px;
  position: absolute;
  z-index: 1;
  & img {
    object-fit: cover;
    width: 100%;
  }
  @media (max-width: 1024px) {
    opacity: 0.5;
  }
`;
const Caption = styled.div`
  position: absolute;
  width: 50%;
  top: 50%;
  left: 50%;
  translate: -50% -50%;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  color: var(--primary);
  z-index: 1;

  @media (max-width: 1024px) {
    width: 95%;
  }
`;
const CareersList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
`;
const CareersHeader = styled.div`
  padding: 1.25rem 1.25rem 0 1.25rem;
`;
const CareerOptions = styled.div`
  display: flex;
  gap: 1.25rem;
  & span {
    padding: 0.625rem 1.25rem;
    background-color: #6feaaf33;
    color: #317180;
    border-radius: 4px;
    &.alternative {
      background-color: #8a8a8a2b;
      color: #565972;
    }
  }
`;
const CareerExpand = styled.div`
  width: 100%;
  height: 0;
  overflow: hidden;
  padding: 0.625rem 1.25rem 0.625rem 1.25rem;
  box-sizing: border-box;
  transition: 0.3s;
  & p,
  h4,
  ul {
    opacity: 0;
  }
  & a {
    text-decoration: underline;
    color: #66b1ad;
  }
`;
const CareerToggle = styled.div.attrs((props) => ({ className: "item" }))`
  width: 100%;
  height: fit-content;
  border: 2px solid #b4d7d0;
  cursor: pointer;
  border-radius: 10px;
  background-color: var(--primary);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  transition: 0.3s;
  &.active > ${CareersHeader} {
    padding: 1.25rem;
  }
  &.active > ${CareerExpand} {
    border-top: 2px solid #b4d7d0;
    height: fit-content;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
      0px 0px 1px rgba(0, 0, 0, 0.04);
    & p,
    h4,
    ul {
      opacity: 1;
    }
  }
`;
//CAREER FORM COMPONENTS
// const CareerForm = styled.form`
//   width: 100%;
//   height: fit-content;
//   border: 2px solid #b4d7d0;
//   padding: 1.25rem;
//   box-sizing: border-box;
//   border-radius: 10px;
//   box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
//   & p {
//     margin: 0;
//     margin-top: 1.25rem;
//     color: #565972;
//   }

//   @media (max-width: 1024px) {
//     margin: 3.125rem 0;
//   }
// `;
// const CheckBox = styled.div`
//   width: 100%;
//   display: flex;
//   gap: 0.625rem;
// `;
// const FlexRow = styled.div`
//   width: 100%;
//   display: flex;
//   gap: 1.25rem;
//   @media (max-width: 1024px) {
//     flex-direction: column;
//   }
// `;
// const FlexColumn = styled.div`
//   width: 100%;
//   display: flex;
//   flex-direction: column;
//   gap: 1.25rem;
// `;

// const InputText = styled.input`
//   border: 1px solid #e2e8f0;
//   border-radius: 6px;
//   box-sizing: border-box;
//   background-color: var(--primary);
//   padding: 0.625rem;

//   &::placeholder {
//     color: #d0d5dd;
//   }
// `;
// const TextArea = styled.textarea`
//   border: 1px solid #e2e8f0;
//   border-radius: 6px;
//   box-sizing: border-box;
//   background-color: var(--primary);
//   padding: 0.625rem;
//   resize: none;
//   width: 100%;

//   &::placeholder {
//     color: #d0d5dd;
//   }
// `;
// const SelectWrap = styled.div`
//   width: 100%;
//   height: 40px;
//   position: relative;
//   z-index: 0;
//   &::after {
//     content: " ";
//     display: block;
//     position: absolute;
//     width: 1rem;
//     height: 1rem;
//     right: 0.625rem;
//     top: 50%;
//     transform: translate(0, -25%);
//     background-color: #565972;
//     z-index: 1;
//     clip-path: polygon(100% 0, 0 0, 50% 50%);
//   }
// `;
// const SelectInput = styled.select`
//   top: 0;
//   border: 1px solid #e2e8f0;
//   border-radius: 6px;
//   background-color: var(--primary);
//   width: 100%;
//   height: 100%;
//   padding: 0.625rem;
//   box-sizing: border-box;
//   z-index: 1;
//   appearance: none;
//   outline: none;
//   position: absolute;
// `;
// const FileWrap = styled.div`
//   position: relative;
//   height: 200px;
//   border: 1px dashed #d0d5dd;
//   border-radius: 8px;
//   margin: 1.25rem 0;
//   & input[type="file"] {
//     position: absolute;
//     width: 100%;
//     height: 100%;
//     opacity: 0;
//     cursor: pointer;
//   }
//   & p {
//     position: absolute;
//     width: 100%;
//     text-align: center;
//     left: 50%;
//     top: 50%;
//     transform: translate(-50%, -50%);
//   }
// `;
// const SubmitButton = styled.button`
//   border: 2px solid #317180;
//   width: 100%;
//   height: 3.125rem;
//   background-color: var(--primary);
//   border-radius: 8px;
//   font-size: 1.125rem;
//   font-weight: bold;
//   color: #317180;
// `;
const ComputerImage = styled.div`
  position: absolute;
  left: 0;
  bottom: 15%;
  width: 25%;
  height: 50%;
  z-index: 2;
  & img {
    object-fit: contain;
    height: 100%;
    width: 100%;
    animation: ComputerRotation 10s ease-in-out infinite;
  }
  & #mobile {
    position: absolute;
    width: 50%;
    height: 50%;
    right: 0;
    top: 25%;
    animation: MobileRotation 10s ease-in-out infinite;
  }

  @media (max-width: 1024px) {
    display: none;
  }
`;
function Careers() {
  const { t } = useTranslation();
  function toggleClass(e) {
    let newTarget = jQuery(e.target);
    if (!newTarget.hasClass("item")) {
      newTarget = jQuery(e.target).parents(".item");
    }
    if (!newTarget.hasClass("active")) {
      jQuery(".item.active").removeClass("active");
      newTarget.addClass("active");
    }
  }
  useEffect(() => {
    var box = jQuery("#box");
    var width = box.width();
    var height = box.height();
    var ship = jQuery(".ship");

    ship.each(function shipmove() {
      var top = (Math.random() * height - 100) | 0;
      var left = (Math.random() * width - 100) | 0;
      var time = 4000;

      jQuery(this).animate({ top: top, left: left }, time, shipmove);
    });
  }, []);

  return (
    <CareerWrap>
      <CareerHero id="box">
        <ComputerImage>
          <img src={require("../assets/img/careers/computer 4.png")} alt="" />
          <img
            id="mobile"
            src={require("../assets/img/careers/mobile 2.png")}
            alt=""
          />
        </ComputerImage>
        <Ship>
          <img src={require("../assets/img/careers/rocket_2.png")} alt="" />
        </Ship>
        <Caption>
          <h2>{t("tab4")}</h2>
          <p>{t("career1Txt")}</p>
        </Caption>
      </CareerHero>
      <CareerSection>
        <CareersList>
          <CareerToggle onClick={(e) => toggleClass(e)}>
            <CareersHeader>
              <h3>{t("sect4opt2")}</h3>
              <CareerOptions>
                <span>Full-Time</span>
                <span className="alternative">{t("careerlabel")}</span>
              </CareerOptions>
            </CareersHeader>
            <CareerExpand>
              <p>{t("career2Txt")}</p>
              <h4>{t("career1Txt9")}</h4>
              <ul>
                <li>{t("career1Txt10")}</li>
                <li>{t("career1Txt11")}</li>
                <li>{t("career1Txt12")}</li>
              </ul>
              <a
                href="mailto:info@seehealth.pt?subject=Candidatura Espontanea"
                _self
              >
                {t("careerBtn")}
              </a>
            </CareerExpand>
          </CareerToggle>
        </CareersList>
        {/* <CareerForm>
          <h4>{t("apply")}</h4>
          <FlexColumn>
            <p>{t("apply2")}</p>
            <InputText placeholder={t("apply2")} />
          </FlexColumn>
          <FlexRow>
            <FlexColumn>
              <p>{t("apply3")}</p>
              <InputText placeholder="example@mail.com" />
            </FlexColumn>
            <FlexColumn>
              <p>{t("apply4")}</p>
              <InputText placeholder={t("apply4")} />
            </FlexColumn>
          </FlexRow>
          <p>{t("apply5")}</p>
          <TextArea placeholder="Escreva Aqui" rows={"6"} />
          <p>{t("apply7")}</p>
          <SelectWrap>
            <SelectInput>
              <option disabled={true}>{t("sect4opt1")}</option>
              <option disabled={true}>{t("sect4opt3")}</option>
              <option>{t("sect4opt2")}</option>
            </SelectInput>
          </SelectWrap>
          <p>{t("apply8")}</p>
          <FlexColumn>
            <CheckBox>
              <input name="experience" type="radio" />
              <label>{t("apply8opt1")}</label>
            </CheckBox>
            <CheckBox>
              <input name="experience" type="radio" />
              <label>{t("apply8opt2")}</label>
            </CheckBox>
            <CheckBox>
              <input name="experience" type="radio" />
              <label>{t("apply8opt3")}</label>
            </CheckBox>
            <CheckBox>
              <input name="experience" type="radio" />
              <label>{t("apply8opt4")}</label>
            </CheckBox>
          </FlexColumn>
          <FileWrap>
            {hasFile !== "" ? <p>{hasFile}</p> : <p>{t("apply6")}</p>}
            <input type={"file"} onChange={(e) => SetFile(e.target.value)} />
          </FileWrap>

          <SubmitButton type="submit">{t("applyBtn2")}</SubmitButton>
        </CareerForm> */}
      </CareerSection>
    </CareerWrap>
  );
}

export default Careers;
