import React, { useEffect, useState } from "react";
import About from "../components/about";
import CareerSection from "../components/careerssection";
import Contacts from "../components/contacts";
import Hero from "../components/hero";
import Loading from "../components/loading";
import Projects from "../components/projects";
import Services from "../components/services";

function Home() {
  const [isLoading, SetLoading] = useState(false);
  const [animate, SetAnimate] = useState(false);
  const [dontAnimate, setDontAnimate] = useState(false);
  const [startRendering, setStartRendering] = useState(false);

  useEffect(() => {
    setDontAnimate(false);
    if (!dontAnimate) {
      SetLoading(true);
      setTimeout(() => {
        setStartRendering(true);
      }, 1500);
      setTimeout(() => {
        SetAnimate(true);
        setTimeout(() => {
          SetLoading(false);
        }, 200);
      }, 2000);
    } else {
      setStartRendering(true);
    }
  }, [dontAnimate]);
  return (
    <>
      {isLoading && !dontAnimate ? (
        <>
          <Loading classes={animate ? "fade-back" : ""} />
        </>
      ) : null}
      <>
        {startRendering && (
          <div
            style={
              !animate ? { display: "none", height: 0 } : { overflow: "hidden" }
            }
          >
            <Hero />
            <div id="content" role="main">
              <About />
            </div>
            <Services />
            <Projects />
            <CareerSection />
            <Contacts />
          </div>
        )}
      </>
    </>
  );
}

export default Home;
