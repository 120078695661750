import React, { useRef } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import emailjs from "@emailjs/browser";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const HeroWrap = styled.div`
  width: 100%;
  min-height: 65vh;
  display: flex;
  flex-direction: column;
  position: relative;
  box-sizing: border-box;
  padding-top: 120px;
  margin-bottom: 50px;
  @media (max-width: 1024px) {
    min-height: 55vh;
  }
  @media (max-width: 768px) {
    min-height: 60vh;
  }
  @media (max-width: 425px) {
    min-height: 75vh;
  }
  &::before {
    content: "";
    position: absolute;
    width: 100%;
    min-height: 50vh;
    left: 0;
    top: 0;
    z-index: -1;
    background: linear-gradient(169.85deg, #a8ddda 17.02%, #66b1ad 149.42%);

    @media (max-width: 1024px) {
      min-height: 55vh;
    }
    @media (max-width: 768px) {
      min-height: 60vh;
    }
    @media (max-width: 425px) {
      min-height: 75vh;
    }
  }
`;

const HeroSection = styled.div`
  max-width: "1366px";
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  h1 {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    color: white;
  }
  p {
    color: white;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
  }

  @media (max-width: 1024px) {
    margin-inline: 5rem;
  }

  @media (max-width: 425px) {
    margin-inline: 2.5rem;
  }
`;

const AboutSectionReversedimg = styled.div`
  width: 100%;
  height: 450px;
  position: relative;
  @media (max-width: 1025px) {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    gap: 2.5rem;
    flex-direction: column;
  }
`;

const AboutWrap = styled.div`
  width: 100%;
  margin-bottom: 15rem;
  margin-top: 5rem;

  @media (max-width: 1024px) {
    margin: 5rem 0;
  }
`;

const AboutContent = styled.div`
  position: relative;
  z-index: 1;
  max-width: 1366px;
  height: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2.5rem;
  @media (max-width: 1364px) {
    box-sizing: border-box;
    width: 90%;
    gap: 1.125rem;
  }
  @media (max-width: 1024px) {
    box-sizing: border-box;
    width: 90%;
    flex-direction: column;
    gap: 1.125rem;
  }
`;
const TextSection = styled.div`
  width: 100%;
  position: relative;
  margin: 2.5rem;
  & h2 {
    color: #035561;
    margin-top: 2.5rem;
  }
  @media (max-width: 1366px) {
    margin: 0 auto;
    width: 100%;
  }
`;

const FormTitle = styled.p`
  color: #317180;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  margin-bottom: 0.8rem;
`;

const FormInput = styled.div`
  background: #fbfbfb;
  border: 1px solid #e2e8f080;
  border-radius: 6px;
  min-height: 45px;
  width: 100%;

  & input,
  select {
    outline: 0;
    border: 0;
    height: 45px;
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
    background: #fbfbfb;

    &:focus {
      border: 0.792793px solid rgba(7, 20, 99, 0.5);
      box-shadow: 0px 0px 6px rgba(7, 20, 99, 0.5);
    }
    &::placeholder {
      color: #a0a8b1;
    }
  }

  & textarea {
    width: 100%;
    resize: none;
    border: 0;
    outline: 0;
    padding: 10px 20px;
    box-sizing: border-box;
    &:focus {
      border: 0.792793px solid rgba(7, 20, 99, 0.5);
      box-shadow: 0px 0px 6px rgba(7, 20, 99, 0.5);
    }
    &::placeholder {
      color: #a0a8b1;
    }
  }
`;

const FormRow = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  width: 100%;
  flex-wrap: nowrap;
  @media (max-width: 1024px) {
    flex-wrap: wrap;
  }
`;

const FormButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 20px;
  & button {
    background: linear-gradient(169.85deg, #a8ddda 17.02%, #66b1ad 149.42%);
    border-radius: 8px;
    color: white;
    padding: 8px 16px;
    border: none;
    font-size: 18px;
    cursor: pointer;
  }
  @media (max-width: 768px) {
    justify-content: center;
  }
`;

function Contacts() {
  const { t } = useTranslation();

  const { register, handleSubmit, watch } = useForm();

  const form = useRef();

  const sendEmail = (data) => {
    emailjs
      .send("service_30assso", "template_0jc07ig", data, "qiaS-GsxWs7mieOEA")
      .then(
        (result) => {
          toast("Email sent successfully");
          form.current.reset();
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  return (
    <>
      <HeroWrap>
        <HeroSection>
          <h1>{t("reports")}</h1>
          <div style={{ maxWidth: "1366px", margin: "0 auto" }}>
            <p>{t("report_subtitle_1")}</p>
            <p>{t("report_subtitle_2")}</p>
          </div>
        </HeroSection>
      </HeroWrap>

      <AboutWrap>
        <AboutSectionReversedimg>
          <AboutContent>
            <TextSection>
              <div>
                <form
                  style={{ display: "flex", flexDirection: "column" }}
                  ref={form}
                  onSubmit={handleSubmit(sendEmail)}
                >
                  <FormRow>
                    <div style={{ width: "100%" }}>
                      <FormTitle>{t("report_anonymous")}</FormTitle>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "15px",
                        }}
                      >
                        <div style={{ display: "flex", gap: "5px" }}>
                          <input
                            type="radio"
                            id="sim"
                            name="anonymous"
                            value="true"
                            {...register("anonymous", { required: true })}
                          />
                          <label for="sim">{t("report_form_yes")}</label>
                        </div>
                        <div style={{ display: "flex", gap: "5px" }}>
                          <input
                            type="radio"
                            id="nao"
                            name="anonymous"
                            value="false"
                            {...register("anonymous", { required: true })}
                          />
                          <label for="nao">{t("report_form_no")}</label>
                        </div>
                      </div>
                    </div>
                  </FormRow>
                  {watch("anonymous") === "false" && (
                    <>
                      <FormRow>
                        <div style={{ width: "100%" }}>
                          <FormTitle>{t("report_fullName")}</FormTitle>
                          <FormInput>
                            <input
                              type={"text"}
                              name="from_name"
                              {...register("from_name", { required: false })}
                              placeholder={t("contactUs_form_placeholder_1")}
                            />
                          </FormInput>
                        </div>
                      </FormRow>

                      <FormRow>
                        <div style={{ width: "100%" }}>
                          <FormTitle>
                            {t("contactUs_form_email")}{" "}
                            <span style={{ color: "red", fontSize: "18px" }}>
                              *
                            </span>
                          </FormTitle>
                          <FormInput>
                            <input
                              type={"email"}
                              placeholder={t("contactUs_form_placeholder_1")}
                              name="from_email"
                              {...register("from_email", { required: true })}
                            />
                          </FormInput>
                        </div>
                        <div style={{ width: "100%" }}>
                          <FormTitle>
                            {t("contactUs_form_phoneNumber")}{" "}
                            <span style={{ color: "red", fontSize: "18px" }}>
                              *
                            </span>
                          </FormTitle>
                          <FormInput>
                            <input
                              type={"text"}
                              placeholder={t("contactUs_form_placeholder_1")}
                              name="phone_number"
                              {...register("phone_number", { required: true })}
                            />
                          </FormInput>
                        </div>
                      </FormRow>
                    </>
                  )}
                  <FormRow>
                    <div style={{ width: "100%" }}>
                      <FormTitle>{t("report_subject")}</FormTitle>
                      <FormInput>
                        <select
                          id="select-input"
                          name="report_subject"
                          {...register("report_subject", { required: true })}
                        >
                          <option value="">
                            {t("contactUs_form_placeholder_2")}
                          </option>
                          <option value="corruption">
                            {t("report_subject_option_1")}
                          </option>
                          <option value="good_practices">
                            {t("report_subject_option_2")}
                          </option>
                          <option value="data_protection">
                            {t("report_subject_option_3")}
                          </option>
                          <option value="other">
                            {t("report_subject_option_4")}
                          </option>
                        </select>
                      </FormInput>
                    </div>
                    <div style={{ width: "100%" }}>
                      <FormTitle>{t("report_relationship")}</FormTitle>
                      <FormInput>
                        <select
                          id="select-input"
                          name="report_relationship"
                          {...register("report_relationship", {
                            required: true,
                          })}
                        >
                          <option value="">
                            {t("contactUs_form_placeholder_2")}
                          </option>
                          <option value="budget">
                            {t("report_relationship_option_1")}
                          </option>
                          <option value="information">
                            {t("report_relationship_option_2")}
                          </option>
                          <option value="other">
                            {t("report_relationship_option_3")}
                          </option>
                          <option value="other">
                            {t("report_relationship_option_4")}
                          </option>
                          <option value="other">
                            {t("report_relationship_option_5")}
                          </option>
                          <option value="other">
                            {t("report_relationship_option_6")}
                          </option>
                          <option value="other">
                            {t("report_relationship_option_7")}
                          </option>
                        </select>
                      </FormInput>
                    </div>
                  </FormRow>
                  <FormRow>
                    <div style={{ width: "100%" }}>
                      <FormTitle>
                        {t("report_date")}{" "}
                        <span style={{ color: "red", fontSize: "18px" }}>
                          *
                        </span>
                      </FormTitle>
                      <FormInput>
                        <input
                          type={"date"}
                          name="report_date"
                          {...register("report_date", {
                            required: true,
                          })}
                          placeholder={t("contactUs_form_placeholder_1")}
                        />
                      </FormInput>
                    </div>
                  </FormRow>
                  <FormRow>
                    <div style={{ width: "100%" }}>
                      <FormTitle>{t("report_description")}</FormTitle>
                      <FormInput>
                        <textarea
                          rows={5}
                          placeholder={t("contactUs_form_placeholder_1")}
                        />
                      </FormInput>
                    </div>
                  </FormRow>

                  <FormRow>
                    <div style={{ width: "100%" }}>
                      <FormButton>
                        <button type="submit">{t("form_submit")}</button>
                      </FormButton>
                    </div>
                  </FormRow>
                  <ToastContainer
                    position="bottom-center"
                    autoClose={4000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable={false}
                    pauseOnHover
                    theme="dark"
                  />
                </form>
              </div>
            </TextSection>
          </AboutContent>
        </AboutSectionReversedimg>
      </AboutWrap>
    </>
  );
}

export default Contacts;
