import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import ButtonPopUp from "./buttonpopup";
import jQuery from "jquery";
const ServicesWrap = styled.div`
  width: 100%;
  min-height: 100vh;
  background-color: var(--secondary);
  box-sizing: border-box;
  padding: 2.5rem 0;
  position: relative;
  & #bg {
    position: absolute;
    width: 100%;
    object-fit: cover;
  }
  @media (max-width: 1024px) {
    height: auto;
  }
`;
const ServicesSection = styled.div`
  max-width: 1366px;
  margin: 0 auto;
  color: var(--primary);
  & h2 {
    margin: 5rem 0;
  }
  @media (max-width: 1365px) {
    width: 90%;
  }
  @media (max-width: 1024px) {
    box-sizing: border-box;
    width: 90%;
    flex-direction: column;
    padding: 0 1.25rem;
  }
`;
const ButtonSection = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 2.5rem;
  justify-content: center;
`;
const PopUpBox = styled.div`
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  position: fixed;
  background: rgba(0, 0, 0, 0.2);
`;
const Box = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 50%;
  height: fit-content;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: relative;
  border-radius: 15px;
  overflow: hidden;
  @media (max-width: 1364px) {
    width: 75%;
    overflow-y: overlay;
  }
  @media (max-width: 1024px) {
    width: 95%;
    grid-template-columns: unset;
    overflow-y: overlay;
  }
`;
const Left = styled.div`
  background: linear-gradient(169.85deg, #a8ddda 17.02%, #66b1ad 149.42%);
  & div {
    box-sizing: border-box;
    padding: 2.5rem;
    color: var(--primary);
  }
`;
const Right = styled.div`
  background: var(--primary);
  & div {
    box-sizing: border-box;
    padding: 2.5rem;
  }
  & h3 {
    color: var(--text);
  }
  & ul {
    padding: 0;
    margin: 0;
  }
  & li {
    padding: 1rem 0;
  }
`;
function Services() {
  const { t } = useTranslation();

  const [PopUpOverlay, setOverlay] = useState(false);
  const [eng, setEng] = useState(false);
  const [ui, setUi] = useState(false);
  const [multi, setMulti] = useState(false);
  const [app, setApp] = useState(false);
  const [quali, setQuali] = useState(false);
  const [supp, setSupp] = useState(false);

  function toggleClass(e) {
    let newTarget = jQuery(e.target);
    if (!newTarget.hasClass("item")) {
      newTarget = jQuery(e.target).parents(".item");
    }
    switch (newTarget.attr("id")) {
      case "eng":
        setEng(true);
        setOverlay(true);
        break;
      case "ui":
        setUi(true);
        setOverlay(true);
        break;
      case "multi":
        setMulti(true);
        setOverlay(true);
        break;
      case "app":
        setApp(true);
        setOverlay(true);
        break;
      case "quali":
        setQuali(true);
        setOverlay(true);
        break;
      case "supp":
        setSupp(true);
        setOverlay(true);
        break;

      default:
        break;
    }
  }
  function ToggleOff() {
    setOverlay(false);
    setEng(false);
    setUi(false);
    setMulti(false);
    setApp(false);
    setQuali(false);
    setSupp(false);
  }
  useEffect(() => {
    if (PopUpOverlay === true) {
      jQuery("body").css("overflow-y", "hidden");
    } else {
      jQuery("body").css("overflow-y", "unset");
    }
  }, [PopUpOverlay]);
  return (
    <>
      <ServicesWrap id={"services"}>
        <img
          id="bg"
          src={require("../assets/img/services/servicesbg.png")}
          alt=""
        />
        <ServicesSection>
          <h2>{t("tab2")}</h2>
          <ButtonSection id="buttons">
            <ButtonPopUp
              id={"eng"}
              name={t("sect2opt1")}
              image={require("../assets/img/services/computer.png")}
              onClick={(e) => toggleClass(e)}
            />
            <ButtonPopUp
              id={"ui"}
              name={t("sect2opt2")}
              image={require("../assets/img/services/ui ux.png")}
              onClick={(e) => toggleClass(e)}
            />
            <ButtonPopUp
              id={"multi"}
              name={t("sect2opt5")}
              image={require("../assets/img/services/crossplatform.png")}
              onClick={(e) => toggleClass(e)}
            />
            <ButtonPopUp
              id={"app"}
              name={t("sect2opt6")}
              image={require("../assets/img/services/rocket.png")}
              onClick={(e) => toggleClass(e)}
            />
            <ButtonPopUp
              id={"quali"}
              name={t("sect2opt4")}
              image={require("../assets/img/services/quality.png")}
              onClick={(e) => toggleClass(e)}
            />
            <ButtonPopUp
              id={"supp"}
              name={t("sect2opt3")}
              image={require("../assets/img/services/headphones.png")}
              onClick={(e) => toggleClass(e)}
            />
          </ButtonSection>
        </ServicesSection>
      </ServicesWrap>
      <PopUpBox
        style={
          PopUpOverlay ? { visibility: "visible" } : { visibility: "hidden" }
        }
        onClick={ToggleOff}
      >
        <Box style={eng ? { display: "grid" } : { display: "none" }}>
          <Left>
            <div>
              <h3>{t("sect2opt1")}</h3>
              <p>{t("engTxt")}</p>
            </div>
          </Left>
          <Right>
            <div>
              <h3>{t("servicoInclui")}</h3>
              <ul>
                <li>{t("engPnt1")}</li>
                <li>{t("engPnt2")}</li>
                <li>{t("engPnt3")}</li>
                <li>{t("engPnt4")}</li>
              </ul>
            </div>
          </Right>
        </Box>
        <Box style={ui ? { display: "grid" } : { display: "none" }}>
          <Left>
            <div>
              <h3>{t("sect2opt2")}</h3>
              <p>{t("designTxt")}</p>
            </div>
          </Left>
          <Right>
            <div>
              <h3>{t("servicoInclui")}</h3>
              <ul>
                <li>{t("designPnt1")}</li>
                <li>{t("designPnt2")}</li>
                <li>{t("designPnt3")}</li>
                <li>{t("designPnt4")}</li>
                <li>{t("designPnt5")}</li>
              </ul>
            </div>
          </Right>
        </Box>
        <Box style={multi ? { display: "grid" } : { display: "none" }}>
          <Left>
            <div>
              <h3>{t("sect2opt5")}</h3>
              <p>{t("multiTxt")}</p>
            </div>
          </Left>
          <Right>
            <div>
              <h3>{t("servicoInclui")}</h3>
              <ul>
                <li>{t("multiPnt1")}</li>
                <li>{t("multiPnt2")}</li>
                <li>{t("multiPnt3")}</li>
                <li>{t("multiPnt4")}</li>
              </ul>
            </div>
          </Right>
        </Box>
        <Box style={app ? { display: "grid" } : { display: "none" }}>
          <Left>
            <div>
              <h3>{t("sect2opt6")}</h3>
              <p>{t("appTxt")}</p>
            </div>
          </Left>
          <Right>
            <div>
              <h3>{t("servicoInclui")}</h3>
              <ul>
                <li>{t("appPnt1")}</li>
                <li>{t("appPnt2")}</li>
                <li>{t("appPnt3")}</li>
                <li>{t("appPnt4")}</li>
                <li>{t("appPnt5")}</li>
              </ul>
            </div>
          </Right>
        </Box>
        <Box style={quali ? { display: "grid" } : { display: "none" }}>
          <Left>
            <div>
              <h3>{t("sect2opt4")}</h3>
              <p>{t("qualityTxt")}</p>
            </div>
          </Left>
          <Right>
            <div>
              <h3>{t("servicoInclui")}</h3>
              <ul>
                <li>{t("qualityPnt1")}</li>
                <li>{t("qualityPnt2")}</li>
                <li>{t("qualityPnt3")}</li>
                <li>{t("qualityPnt4")}</li>
              </ul>
            </div>
          </Right>
        </Box>
        <Box style={supp ? { display: "grid" } : { display: "none" }}>
          <Left>
            <div>
              <h3>{t("sect2opt3")}</h3>
              <p>{t("clientTxt")}</p>
            </div>
          </Left>
          <Right>
            <div>
              <h3>{t("servicoInclui")}</h3>
              <ul>
                <li>{t("clientPnt1")}</li>
                <li>{t("clientPnt2")}</li>
                <li>{t("clientPnt3")}</li>
                <li>{t("clientPnt4")}</li>
              </ul>
            </div>
          </Right>
        </Box>
      </PopUpBox>
    </>
  );
}

export default Services;
