import React from "react";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";

const AboutWrap = styled.div`
  width: 100%;
  margin: 5rem 0;
`;
const AboutSection = styled.div`
  width: 1366px;
  margin: 0 auto;
  display: flex;
  gap: 2.5rem;
  position: relative;
  @media (max-width: 1025px) {
    box-sizing: border-box;
    width: 90%;
    flex-direction: column;
  }
`;
const AboutSectionReversed = styled.div`
  width: 100%;
  z-index: 0;
  position: relative;
  &::before {
    content: "";
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: #fafafa;
    z-index: 0;
    border-top-right-radius: 400px;
    border-bottom-right-radius: 400px;
  }
  @media (max-width: 1025px) {
    &::before {
      display: none;
    }
  }
  @media (min-width: 2000px) {
    height: 500px;
    &::before {
      width: 80%;
    }
  }
`;
const AboutContent = styled.div`
  position: relative;
  z-index: 1;
  max-width: 1366px;
  height: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2.5rem;
  @media (max-width: 1364px) {
    box-sizing: border-box;
    width: 90%;
    gap: 1.125rem;
  }
  @media (max-width: 1024px) {
    box-sizing: border-box;
    width: 90%;
    flex-direction: column;
    gap: 1.125rem;
  }
`;
const ImageSection = styled.div`
  width: 450px;
  height: 450px;
  overflow: hidden;
  border-radius: 50%;
  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  @media (max-width: 1365px) {
    min-width: 320px;
    height: 320px;
  }
`;
const TextSection = styled.div`
  width: 45%;
  position: relative;
  margin-bottom: 2.5rem;
  & h2 {
    color: #035561;
  }
  @media (max-width: 1366px) {
    margin: 0 auto;
    width: 100%;
  }
`;

const AboutSectionimgbg = styled.div`
  width: 100%;
  height: 450px;
  position: relative;
  & ${AboutContent} {
  }
  @media (max-width: 1365px) {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    gap: 2.5rem;
    flex-direction: column;
  }
  @media (min-width: 2000px) {
    height: 500px;
  }
`;
const ImageSectionBG = styled.div`
  position: relative;
  max-height: 500px;
  height: 100%;
  top: 0;
  & img {
    height: 100%;
    width: 100%;
    object-fit: contain;
    position: relative;
    left: 0;
  }
  @media (min-width: 1921px) {
    & img {
      width: unset;
    }
  }
  @media (max-width: 1025px) {
    position: relative;
    width: 100%;

    & img {
      width: 100%;
      height: auto;
      right: 6%;
      left: unset;
    }
  }
`;
const AboutSectionReversedimg = styled.div`
  width: 100%;
  height: 450px;
  position: relative;
  @media (max-width: 1025px) {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    gap: 2.5rem;
    flex-direction: column;
  }
`;
const ImageSectionBGReversed = styled.div`
  position: relative;
  height: 100%;
  max-height: 500px;
  top: 0;
  right: 0;
  & img {
    height: 100%;
    width: 100%;
    object-fit: contain;
    position: relative;
    right: 0;
  }
  @media (max-width: 1025px) {
    position: relative;
    & img {
      width: 100%;
      height: auto;
    }
  }
`;

export function AboutProject({ aboutTitle, aboutContent, aboutImage }) {
  const isMobile = useMediaQuery({ query: "(max-width: 1025px)" });
  return (
    <AboutWrap>
      <AboutSection>
        <AboutContent>
          {isMobile ? (
            <>
              <TextSection>
                <h2>{aboutTitle}</h2>
                <p>{aboutContent}</p>
              </TextSection>
              <ImageSection>
                <img src={aboutImage} alt="" />
              </ImageSection>
            </>
          ) : (
            <>
              <ImageSection>
                <img src={aboutImage} alt="" />
              </ImageSection>
              <TextSection>
                <h2>{aboutTitle}</h2>
                <p>{aboutContent}</p>
              </TextSection>
            </>
          )}
        </AboutContent>
      </AboutSection>
    </AboutWrap>
  );
}

export function AboutProjectReverse({ aboutTitle, aboutContent, aboutImage }) {
  return (
    <AboutWrap>
      <AboutSectionReversed>
        <AboutContent>
          <TextSection>
            <h2>{aboutTitle}</h2>
            <p>{aboutContent}</p>
          </TextSection>
          <ImageSection>
            <img src={aboutImage} alt="" />
          </ImageSection>
        </AboutContent>
      </AboutSectionReversed>
    </AboutWrap>
  );
}
export function AboutProjectImageBGReversed({
  aboutTitle,
  aboutContent,
  aboutImage,
}) {
  return (
    <AboutWrap>
      <AboutSectionReversedimg>
        <AboutContent>
          <TextSection>
            <h2>{aboutTitle}</h2>
            <p>{aboutContent}</p>
          </TextSection>
          <ImageSectionBGReversed>
            <img src={aboutImage} alt="" />
          </ImageSectionBGReversed>
        </AboutContent>
      </AboutSectionReversedimg>
    </AboutWrap>
  );
}

export function AboutProjectImageBG({ aboutTitle, aboutContent, aboutImage }) {
  return (
    <AboutWrap>
      <AboutSectionimgbg>
        <AboutContent>
          <ImageSectionBG>
            <img src={aboutImage} alt="" />
          </ImageSectionBG>
          <TextSection>
            <h2>{aboutTitle}</h2>
            <p>{aboutContent}</p>
          </TextSection>
        </AboutContent>
      </AboutSectionimgbg>
    </AboutWrap>
  );
}
