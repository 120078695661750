import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init();
const CareerBox = styled.div`
  width: calc(50% - 2.5rem);
  box-sizing: border-box;
  padding: 1.25rem;
  color: #000;
  background-color: var(--primary);
  border: 2px solid #e0f3f0;
  border-radius: 8px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
  @media (max-width: 1024px) {
    width: 100%;
  }
`;
const CareerContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  & button {
    min-width: 4rem;
    max-width: 12.5rem;
    height: 50px;
    font-size: 1.125rem;
    padding: 0.625rem 1.25rem;
    border-radius: 8px;
    border: 0;
    background: linear-gradient(169.85deg, #a8ddda 17.02%, #66b1ad 149.42%);
    color: var(--primary);
    font-weight: 600;
    cursor: pointer;
  }
  & p {
    color: var(--text);
  }
`;

function InfoBox({ title, description }) {
  const { t } = useTranslation();
  return (
    <CareerBox data-aos="zoom-out" data-aos-duration="500">
      <CareerContent>
        <h3>{title}</h3>
        <p>{description}</p>
        <button onClick={() => (document.location.href = "/careers")}>
          {t("sect4Btn")}
        </button>
      </CareerContent>
    </CareerBox>
  );
}

export default InfoBox;
