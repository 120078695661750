import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init();
const AboutWrap = styled.div`
  width: 100%;

  &::before {
    content: " ";
    display: block;
    width: 100%;
    height: 150px;
  }
`;
const AboutSection = styled.div`
  max-width: 1366px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  gap: 2.5rem;
  @media (max-width: 1365px) {
    width: 90%;
  }
  @media (max-width: 1024px) {
    box-sizing: border-box;
    width: 90%;
    flex-direction: column;
    padding: 0 1.25rem;
  }
`;
const ImageSection = styled.figure`
  width: 50%;
  height: 50%;
  position: relative;
  margin: 0;
  & img {
    width: 100%;
    object-fit: contain;
  }
  @media (max-width: 1024px) {
    height: auto;
    width: 100%;

    & img {
      height: 100%;
      object-fit: cover;
    }
  }
`;
const TextSection = styled.div`
  width: 50%;
  position: relative;
  margin-bottom: 2.5rem;
  & a {
    text-decoration: none;
  }
  @media (max-width: 1024px) {
    width: 100%;
  }
`;
const AboutLink = styled.p`
  background: var(--secondary);
  border-radius: 50px;
  padding: 0.625rem 1.25rem;
  text-decoration: none;
  color: var(--primary);
  font-weight: 700;
  font-size: 1rem;
  white-space: pre-wrap;
  width: fit-content;
  text-align: center;
`;

function About() {
  const { t } = useTranslation();
  return (
    <AboutWrap id="aboutus">
      <AboutSection>
        <ImageSection data-aos="zoom-in">
          <img
            src={require("../assets/img/general/about/computer_man.png")}
            alt=""
          />
        </ImageSection>
        <TextSection data-aos="fade-left">
          <h2>{t("sect1Txt1") + t("sect1Txt2") + t("sect1Txt3")}</h2>
          <p>{t("sect1Txt4")}</p>
          <p>
            {t("sect1Txt5")} {t("sect1Txt5Link")}
            {t("sect1Txt6")}
          </p>
          <p>{t("sect1TxtBold")}</p>
          <a
            href={
              window.localStorage.getItem("i18nextLng") === "pt"
                ? "https://seehealth-docs.s3.eu-central-1.amazonaws.com/See_Health_Apresentacao.pdf"
                : "https://seehealth-docs.s3.eu-central-1.amazonaws.com/See_Health_Presentation_English.pdf"
            }
            target="_blank"
            rel="noopener noreferrer"
            title="Descarregar o nosso dossier de apresentação"
          >
            <AboutLink>{t("sect1Link")}</AboutLink>
          </a>
        </TextSection>
      </AboutSection>
    </AboutWrap>
  );
}

export default About;
