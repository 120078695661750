import React from "react";
import styled from "styled-components";

const ContentWrap = styled.div`
  width: 100%;
`;
const ContentSection = styled.div`
  max-width: 1366px;
  margin: 0 auto;
  @media (max-width: 1365px) {
    width: 90%;
  }
  @media (max-width: 1024px) {
    width: 90%;
    box-sizing: border-box;
    margin-bottom: 4rem;
  }
`;
const ProjectQuickInfo = styled.div`
  width: 70%;
  margin: 0 auto;
  background: #ffffff;
  box-sizing: border-box;
  padding: 2.5rem;
  border: 1px solid #f0f0f0;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  text-align: center;
  & h3 {
    color: #035561;
    background: linear-gradient(169.85deg, #5cc7cf 17.02%, #317180 149.42%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  & p {
    color: var(--text);
  }
  & a {
    text-decoration: none;
    color: var(--secondary);
    font-weight: bold;
  }
  @media (max-width: 1024px) {
    width: 100%;
  }
`;

function ProjectsContent({
  children,
  quickinfoTitle,
  quickinfoContent,
  quickinfoLink,
}) {
  return (
    <ContentWrap>
      <ContentSection>
        <ProjectQuickInfo>
          <h3>{quickinfoTitle}</h3>
          <p>{quickinfoContent}</p>
          <a
            href={"https://" + quickinfoLink}
            target="_blank"
            without
            rel="noreferrer"
          >
            {quickinfoLink}
          </a>
        </ProjectQuickInfo>
      </ContentSection>
      {children}
    </ContentWrap>
  );
}

export default ProjectsContent;
