import React from "react";
import { useTranslation } from "react-i18next";
import ProjectsContent from "../components/projectcontent";
import {
  AboutProjectImageBG,
  AboutProjectImageBGReversed,
} from "../components/projectsabout";
import ProjectHero from "../components/projectshero";

function MadeiraSafe() {
  const { t } = useTranslation();
  return (
    <>
      <ProjectHero
        title={"Madeira Safe"}
        banner={require("../assets/img/projects/madeirasafe/bannermadeirasafe.png")}
      />
      <ProjectsContent
        quickinfoTitle={t("MadeiraSafeSub")}
        quickinfoContent={t("MadeiraSafeInfo")}
        // quickinfoLink={"www.madeirasafe.com (indisponível)"}
      >
        <AboutProjectImageBG
          aboutTitle={t("MadeiraSafeTitle1")}
          aboutContent={t("MadeiraSafeTxt1")}
          aboutImage={require("../assets/img/projects/madeirasafe/madeirasafe_1.png")}
        />
        <AboutProjectImageBGReversed
          aboutTitle={t("MadeiraSafeTitle2")}
          aboutContent={t("MadeiraSafeTxt2")}
          aboutImage={require("../assets/img/projects/madeirasafe/madeirasafe_2.png")}
        />
        <AboutProjectImageBG
          aboutTitle={t("MadeiraSafeTitle3")}
          aboutContent={t("MadeiraSafeTxt3")}
          aboutImage={require("../assets/img/projects/madeirasafe/madeirasafe_3.png")}
        />
      </ProjectsContent>
    </>
  );
}

export default MadeiraSafe;
