import React from "react";
import styled from "styled-components";
import { ReactComponent as Logo } from "../assets/img/general/SeeHealth_Big.svg";

const FullScreen = styled.div`
  width: 100%;
  height: 100vh;
  background-color: #f2f2f2;
  position: absolute;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  transition: 0.6s ease-in-out;
  opacity: 1;

  &.fade-back {
    opacity: 0;
    overflow: unset;
  }
`;

function Loading({ classes }) {
  return (
    <FullScreen className={classes}>
      <Logo />
    </FullScreen>
  );
}

export default Loading;
