import React from "react";
import styled from "styled-components";
import NavigationProjects from "./navigationprojects";

const HeroWrap = styled.div`
  width: 100%;
  min-height: 70vh;
  display: flex;
  flex-direction: column;
  position: relative;
  box-sizing: border-box;
  padding-top: 120px;
  margin-bottom: 50px;
  @media (max-width: 1024px) {
    height: 50vh;
    box-sizing: border-box;
  }
  &::before {
    content: "";
    position: absolute;
    width: 100%;
    min-height: 50vh;
    left: 0;
    top: 0;
    z-index: -1;
    background: linear-gradient(169.85deg, #a8ddda 17.02%, #66b1ad 149.42%);
  }
`;
const HeroSection = styled.div`
  max-width: 1366px;
  width: 100%;
  margin: 0 auto;
  position: relative;
  color: var(--primary);

  @media (max-width: 1365px) {
    width: 90%;
  }
  @media (max-width: 1024px) {
    width: 90%;
    height: 50%;
    gap: 1.25rem;
    display: flex;
    flex-direction: column;
  }
`;
const HeroImage = styled.div`
  max-width: 1366px;
  margin: 0 auto;
  height: 100%;
  border-radius: 15px;
  position: relative;
  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  @media (max-width: 1365px) {
    width: 90%;
  }
`;

function ProjectHero({ title, banner }) {
  return (
    <HeroWrap>
      <HeroSection>
        <NavigationProjects />
        <h1>{title}</h1>
      </HeroSection>
      <HeroImage>
        <img src={banner} alt="" />
      </HeroImage>
    </HeroWrap>
  );
}

export default ProjectHero;
