import "./main.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Layout from "./components/Layout";
import NavBar from "./components/navbar";
import Home from "./routes/home";
import Footer from "./components/footer";
import MyHealth from "./routes/myhealth";
import ScrollToTop from "./components/scrolltotop";
import SeeApp from "./routes/seeapp";
import Contacts from "./routes/contacts";
import MadeiraSafe from "./routes/madeirasafe";
import Careers from "./routes/careers";
import Privacy from "./routes/privacy";
import BackToTop from "./components/backtotopbtn";
import Reports from "./routes/reports";
import CookiesConsent from "./components/cookiesconsent";

function App() {
  return (
    <Layout>
      <NavBar />
      <CookiesConsent />
      <BackToTop />
      <Router>
        <ScrollToTop>
          <Routes>
            <Route index element={<Home />} />
            <Route path="/madeirasafe" element={<MadeiraSafe />} />
            <Route path="/myehealth" element={<MyHealth />} />
            <Route path="/seeapp" element={<SeeApp />} />
            <Route path="/contacts" element={<Contacts />} />
            <Route path="/careers" element={<Careers />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/reports" element={<Reports />} />
          </Routes>
        </ScrollToTop>
      </Router>
      <Footer />
    </Layout>
  );
}

export default App;
