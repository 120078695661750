import React, { useEffect, useState } from "react";
import styled from "styled-components";
import jQuery from "jquery";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CheckIcon from "@mui/icons-material/Check";
import { useTranslation } from "react-i18next";
// import genderPDF_en from "./../assets/Gender_Equality_Plan.pdf";

const PrivacyWrap = styled.div`
  width: 100%;
  position: relative;
  top: 150px;
  min-height: 100vh;
  margin-bottom: 150px;
  @media (max-width: 1024px) {
    padding: 0 1.25rem;
    box-sizing: border-box;
  }
`;
const PrivacySection = styled.div`
  width: 75%;
  height: 100%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 2.5rem;
  @media (max-width: 1024px) {
    width: 100%;
    display: block;
    padding-bottom: 5rem;
  }
`;
const Menu = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  width: 250px;
  & .menuitem {
    cursor: pointer;
    &:hover {
      color: var(--secondary);
    }
    &.active {
      color: var(--secondary);
    }
  }
`;
const PrivacyPage = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
`;
const InnerContent = styled.div`
  display: block;
  & a {
    color: inherit;
    text-decoration: none;
  }
  & ul {
    color: var(--primary);
  }
`;
const TermsPage = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
`;
const GenderPage = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
`;
const TogglesContent = styled.div`
  position: relative;
  width: 100%;
  top: -10px;
  left: 0;
  box-sizing: border-box;
  padding: 0 1.25rem;
  height: 0;
  background-color: #89c9c5;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  overflow: hidden;
  transition: 0.3s;
  & p {
    color: var(--primary);
    & a {
      color: var(--primary);
      text-decoration: none;
      margin: 0.625rem 0;
    }
  }
`;
const Arrow = styled.div`
  width: 24px;
  height: 24px;
  color: var(--primary);
  transform: rotate(0);
  transition: 0.3s;
`;
const Toggles = styled.div.attrs((props) => ({ className: "item" }))`
  position: relative;
  margin: 0.625rem 0;
  cursor: pointer;
  & p {
    color: var(--primary);
  }
  &.active ${TogglesContent} {
    height: fit-content;
    padding: 1.25rem;
    & p {
      height: 100%;
    }
  }
  &.active ${Arrow} {
    transform: rotate(90deg);
  }
`;
const FlexRow = styled.div`
  box-sizing: border-box;
  padding: 0.625rem 1.25rem;
  height: 4.375rem;
  background-color: #ade0dd;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
`;

function Privacy() {
  const [privacy, SetPrivacy] = useState(false);
  const [terms, SetTerms] = useState(false);
  const [gender, SetGender] = useState(false);

  function toggleClass(e) {
    let newTarget = jQuery(e.target);

    if (!newTarget.hasClass("item")) {
      newTarget = jQuery(e.target).parents(".item");
    }
    newTarget.toggleClass("active");
  }

  function menuToggle(e) {
    let newTarget = jQuery(e.target);
    if (!newTarget.hasClass("menuitem")) {
      newTarget = jQuery(e.target).parents(".menuitem");
    }
    if (!newTarget.hasClass("active")) {
      jQuery(".menuitem.active").removeClass("active");
      newTarget.addClass("active");
    }

    switch (newTarget.attr("id")) {
      case "privacy":
        SetPrivacy(true);
        SetTerms(false);
        SetGender(false);
        break;
      case "terms":
        SetPrivacy(false);
        SetTerms(true);
        SetGender(false);
        break;
      case "gender":
        SetPrivacy(false);
        SetTerms(false);
        SetGender(true);
        break;

      default:
        break;
    }
  }
  useEffect(() => {
    if (window.location.href.indexOf("#privacy") > -1) {
      jQuery(".menuitem.active").removeClass("active");
      jQuery("#privacy").addClass("active");
      SetPrivacy(true);
    } else if (window.location.href.indexOf("#terms") > -1) {
      jQuery(".menuitem.active").removeClass("active");
      jQuery("#terms").addClass("active");
      SetTerms(true);
    } else if (window.location.href.indexOf("#gender") > -1) {
      jQuery(".menuitem.active").removeClass("active");
      jQuery("#gender").addClass("active");
      SetGender(true);
    }
  }, []);
  const {
    t,
    i18n: { language },
  } = useTranslation();

  return (
    <PrivacyWrap>
      <PrivacySection>
        <Menu>
          <span
            id="privacy"
            className="menuitem active"
            onClick={(e) => menuToggle(e)}
          >
            {t("privacyPolicy")}
          </span>
          <span id="terms" className="menuitem" onClick={(e) => menuToggle(e)}>
            {t("termsUse")}
          </span>
          <span id="gender" className="menuitem" onClick={(e) => menuToggle(e)}>
            {t("igualdade")}
          </span>
        </Menu>
        <PrivacyPage
          style={privacy ? { display: "block" } : { display: "none" }}
        >
          <h2>{t("privacyPolicy")}</h2>
          <small>{t("termsTxt")}</small>
          <InnerContent>
            <h3>{t("privacidade")}</h3>
            <p>{t("privacidadeAntes")}</p>
            <Toggles onClick={(e) => toggleClass(e)}>
              <FlexRow>
                <p>{t("privacidade1")}</p>
                <Arrow>
                  <ChevronRightIcon />
                </Arrow>
              </FlexRow>
              <TogglesContent>
                <p>{t("privacidade1Txt")}</p>
                <p>{t("privacidadeExtra")}</p>
              </TogglesContent>
            </Toggles>
            <Toggles onClick={(e) => toggleClass(e)}>
              <FlexRow>
                <p>{t("privacidade2")}</p>
                <Arrow>
                  <ChevronRightIcon />
                </Arrow>
              </FlexRow>
              <TogglesContent>
                <p>{t("privacidade2Txt")}</p>
              </TogglesContent>
            </Toggles>
            <Toggles onClick={(e) => toggleClass(e)}>
              <FlexRow>
                <p>{t("privacidade3")}</p>
                <Arrow>
                  <ChevronRightIcon />
                </Arrow>
              </FlexRow>
              <TogglesContent>
                <p>{t("privacidade3Txt")}</p>
              </TogglesContent>
            </Toggles>
            <Toggles onClick={(e) => toggleClass(e)}>
              <FlexRow>
                <p>{t("privacidade4")}</p>
                <Arrow>
                  <ChevronRightIcon />
                </Arrow>
              </FlexRow>
              <TogglesContent>
                <p>{t("privacidade4Txt1")}</p>
                <p>{t("privacidade4Txt2")}</p>
                <p>{t("privacidade4Txt3")}</p>
              </TogglesContent>
            </Toggles>
            <Toggles onClick={(e) => toggleClass(e)}>
              <FlexRow>
                <p>{t("privacidade5")}</p>
                <Arrow>
                  <ChevronRightIcon />
                </Arrow>
              </FlexRow>
              <TogglesContent>
                <p>{t("privacidade5Txt1")}</p>
              </TogglesContent>
            </Toggles>
            <h3>{t("privacidade6")}</h3>
            <Toggles onClick={(e) => toggleClass(e)}>
              <FlexRow>
                <p>{t("privacidade6sub")}</p>
                <Arrow>
                  <ChevronRightIcon />
                </Arrow>
              </FlexRow>
              <TogglesContent>
                <p>{t("privacidade6Txt1")}</p>
                <p>{t("privacidade6Txt2")}</p>
              </TogglesContent>
            </Toggles>
            <Toggles onClick={(e) => toggleClass(e)}>
              <FlexRow>
                <p>{t("privacidade7")}</p>
                <Arrow>
                  <ChevronRightIcon />
                </Arrow>
              </FlexRow>
              <TogglesContent>
                <p>{t("privacidade7Txt1")}</p>
                <p>{t("privacidade7Txt2")}</p>
                <p>{t("privacidade7Txt3")}</p>
              </TogglesContent>
            </Toggles>
            <Toggles onClick={(e) => toggleClass(e)}>
              <FlexRow>
                <p>{t("privacidade8")}</p>
                <Arrow>
                  <ChevronRightIcon />
                </Arrow>
              </FlexRow>
              <TogglesContent>
                <p>{t("privacidade8Txt1")}</p>
                <p>{t("privacidade8Txt2")}</p>
                <p>{t("privacidade8Txt3")}</p>
                <p>{t("privacidade8Txt4")}</p>
              </TogglesContent>
            </Toggles>
            <Toggles onClick={(e) => toggleClass(e)}>
              <FlexRow>
                <p>{t("privacidade9")}</p>
                <Arrow>
                  <ChevronRightIcon />
                </Arrow>
              </FlexRow>
              <TogglesContent>
                <p>{t("privacidade9Txt1")}</p>
                <p>
                  <a href="https://policies.google.com/technologies/cookies?hl=pt">
                    {t("privacidade9Txt2")}
                  </a>
                </p>
                <p>
                  <a href="https://policies.google.com/privacy?hl=pt">
                    {t("privacidade9Txt3")}
                  </a>
                </p>
                <p>
                  <a href="https://www.facebook.com/policies/cookies">
                    {t("privacidade9Txt4")}
                  </a>
                </p>
              </TogglesContent>
            </Toggles>
            <Toggles onClick={(e) => toggleClass(e)}>
              <FlexRow>
                <p>{t("privacidade10")}</p>
                <Arrow>
                  <ChevronRightIcon />
                </Arrow>
              </FlexRow>
              <TogglesContent>
                <p>{t("privacidade10Txt1")}</p>
                <p>{t("privacidade10Txt2")}</p>
                <p>
                  <a href="https://support.microsoft.com/pt-pt/windows/eliminar-egerir-%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20cookies-168dab11-0753-043d-7c16-ede5947fc64d">
                    {t("privacidade10Txt3")}
                  </a>
                </p>
                <p>
                  <a href="https://support.apple.com/pt-pt/HT201265">
                    {t("privacidade10Txt4")}
                  </a>
                </p>
                <p>
                  <a href="https://support.google.com/chrome/answer/95647?hl=pt&hlrm=en">
                    {t("privacidade10Txt5")}
                  </a>
                </p>
                <p>
                  <a href="https://support.mozilla.org/pt-PT/kb/cookies-informacao-quewebsites-%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20guardam-no-seu-computador">
                    {t("privacidade10Txt6")}
                  </a>
                </p>
                <p>
                  <a href="http://help.opera.com/Windows/12.10/en/cookies.html">
                    {t("privacidade10Txt7")}
                  </a>
                </p>
                <p>
                  <a href="https://support.google.com/android/?hl=pt">
                    {t("privacidade10Txt8")}
                  </a>
                </p>
                <p>
                  <a href="https://support.microsoft.com/pt-PT/windows/windowsphone-%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%207-3ebc303c-59c0-d367-3995-f258b184fabb">
                    {t("privacidade10Txt9")}
                  </a>
                </p>
                <p>
                  <a href="https://www.blackberry.com/us/en/legal/privacy-notice">
                    {t("privacidade10Txt10")}
                  </a>
                </p>
                <p>
                  <a href="https://support.apple.com/pt-pt/HT201265">
                    {t("privacidade10Txt11")}
                  </a>
                </p>
                <p>
                  <a href="https://support.google.com/chrome/answer/114662?hl=pt&visit_id=63765484997439%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%205837-2505098091&rd=1">
                    {t("privacidade10Txt12")}
                  </a>
                </p>
                <p>
                  <a href="http://support.mozilla.org/en/kb/limpe-your-cookies-private-historico-e-conf">
                    {t("privacidade10Txt13")}
                  </a>
                </p>
                <p>{t("privacidade10Txt14")}</p>
                <p>{t("privacidade10Txt15")}</p>
              </TogglesContent>
            </Toggles>
          </InnerContent>
        </PrivacyPage>
        <TermsPage style={terms ? { display: "block" } : { display: "none" }}>
          <h2>{t("termsUse")}</h2>
          <small>{t("termsTxt")}</small>
          <InnerContent>
            <Toggles onClick={(e) => toggleClass(e)}>
              <FlexRow>
                <p>{t("terms1")}</p>
                <Arrow>
                  <ChevronRightIcon />
                </Arrow>
              </FlexRow>
              <TogglesContent>
                <p>{t("terms1Txt1")}</p>
                <p>{t("terms1Txt2")}</p>
                <p>{t("terms1Txt3")}</p>
                <p>{t("terms1Txt4")}</p>
                <p>{t("terms1Txt5")}</p>
                <p>{t("terms1Txt6")}</p>
              </TogglesContent>
            </Toggles>
            <Toggles onClick={(e) => toggleClass(e)}>
              <FlexRow>
                <p>{t("terms2")}</p>
                <Arrow>
                  <ChevronRightIcon />
                </Arrow>
              </FlexRow>
              <TogglesContent>
                <p>{t("terms2Txt1")}</p>
                <p>{t("terms2Txt2")}</p>
              </TogglesContent>
            </Toggles>
            <Toggles onClick={(e) => toggleClass(e)}>
              <FlexRow>
                <p>{t("terms3")}</p>
                <Arrow>
                  <ChevronRightIcon />
                </Arrow>
              </FlexRow>
              <TogglesContent>
                <p>{t("terms3Txt1")}</p>
                <p>{t("terms3Txt2")}</p>
              </TogglesContent>
            </Toggles>
            <Toggles onClick={(e) => toggleClass(e)}>
              <FlexRow>
                <p>{t("terms4")}</p>
                <Arrow>
                  <ChevronRightIcon />
                </Arrow>
              </FlexRow>
              <TogglesContent>
                <p>{t("terms4Txt1")}</p>
                <p>{t("terms4Txt2")}</p>
                <p>{t("terms4Txt3")}</p>
              </TogglesContent>
            </Toggles>
            <Toggles onClick={(e) => toggleClass(e)}>
              <FlexRow>
                <p>{t("terms5")}</p>
                <Arrow>
                  <ChevronRightIcon />
                </Arrow>
              </FlexRow>
              <TogglesContent>
                <p>{t("terms5Txt1")}</p>
              </TogglesContent>
            </Toggles>
          </InnerContent>
        </TermsPage>
        <GenderPage style={gender ? { display: "block" } : { display: "none" }}>
          <h2>{t("igualdadeTtl")}</h2>
          <small>{t("igualdadeAct")}</small>
          <InnerContent>
            <Toggles onClick={(e) => toggleClass(e)}>
              <FlexRow>
                <p>{t("igualdade1")}</p>
                <Arrow>
                  <ChevronRightIcon />
                </Arrow>
              </FlexRow>
              <TogglesContent>
                <p>{t("igualdade1txt1")}</p>
                <p>{t("igualdade1txt2")}</p>
                <p>{t("igualdade1txt3")}</p>
                <p>{t("igualdade1txt4")}</p>
                <ul>
                  <li>{t("igualdade1txt4p1")}</li>
                  <li>{t("igualdade1txt4p2")}</li>
                  <li>{t("igualdade1txt4p3")}</li>
                  <li>{t("igualdade1txt4p4")}</li>
                  <li>{t("igualdade1txt4p5")}</li>
                  <ul>
                    <li>{t("igualdade1txt4p5p1")}</li>
                    <li>{t("igualdade1txt4p5p2")}</li>
                    <li>{t("igualdade1txt4p5p3")}</li>
                    <li>{t("igualdade1txt4p5p4")}</li>
                  </ul>
                </ul>
              </TogglesContent>
            </Toggles>
            <h3>{t("igualdad2")}</h3>
            <Toggles onClick={(e) => toggleClass(e)}>
              <FlexRow>
                <p>{t("igualdad2p1")}</p>
                <Arrow>
                  <ChevronRightIcon />
                </Arrow>
              </FlexRow>
              <TogglesContent>
                <p>{t("igualdad2p1txt1")}</p>
                <p>{t("igualdad2p1txt2")}</p>
                <ul>
                  <li>{t("igualdad2p1txt2p1")}</li>
                  <li>{t("igualdad2p1txt2p2")}</li>
                </ul>
                <p>{t("igualdad2p1txt3")}</p>
                <ul>
                  <li>{t("igualdad2p1txt3p1")}</li>
                  <li>{t("igualdad2p1txt3p2")}</li>
                  <li>{t("igualdad2p1txt3p3")}</li>
                  <li>{t("igualdad2p1txt3p4")}</li>
                </ul>
                <p>{t("igualdad2p1txt4")}</p>
                <p>{t("igualdad2p1txt5")}</p>
                <p>{t("igualdad2p1txt6")}</p>
                <p>
                  <b>{t("igualdad2p1bold1")}</b>
                </p>
                <ul>
                  <li>{t("igualdad2p1bold3p1")}</li>
                  <li>{t("igualdad2p1bold3p2")}</li>
                  <li>{t("igualdad2p1bold3p3")}</li>
                </ul>
                <p>
                  <b>{t("igualdad2p1bold2")}</b>
                </p>
                <ul>
                  <li>{t("igualdad2p1bold2p1")}</li>
                  <li>{t("igualdad2p1bold1p1")}</li>
                  <li>{t("igualdad2p1bold1p3")}</li>
                </ul>
                <p>{t("igualdad2p1txt7")}</p>
                <p>
                  <b>{t("igualdad2p1bold4")}</b>
                </p>
                <ul>
                  <li>{t("igualdad2p1bold4p1")}</li>
                  <li>{t("igualdad2p1bold4p2")}</li>
                  <li>{t("igualdad2p1bold4p3")}</li>
                </ul>
                <p>
                  <b>{t("igualdad2p1bold5")}</b>
                </p>
                <ul>
                  <li>{t("igualdad2p1bold5p1")}</li>
                  <li>{t("igualdad2p1bold5p2")}</li>
                  <li>{t("igualdad2p1bold5p3")}</li>
                </ul>
                <p>
                  <b>{t("igualdad2p1bold6")}</b>
                </p>
                <ul>
                  <li>{t("igualdad2p1bold6p1")}</li>
                  <li>{t("igualdad2p1bold6p2")}</li>
                  <li>{t("igualdad2p1bold6p3")}</li>
                </ul>
                <p>
                  <b>{t("igualdad2p1bold7")}</b>
                </p>
                <ul>
                  <li>{t("igualdad2p1bold7p1")}</li>
                  <li>{t("igualdad2p1bold7p2")}</li>
                  <li>{t("igualdad2p1bold7p3")}</li>
                </ul>
              </TogglesContent>
            </Toggles>
            <Toggles onClick={(e) => toggleClass(e)}>
              <FlexRow>
                <p>{t("igualdad2p2")}</p>
                <Arrow>
                  <ChevronRightIcon />
                </Arrow>
              </FlexRow>
              <TogglesContent>
                <p>{t("igualdad2p2txt")}</p>
              </TogglesContent>
            </Toggles>
            <Toggles onClick={(e) => toggleClass(e)}>
              <FlexRow>
                <p>{t("igualdad2p3")}</p>
                <Arrow>
                  <ChevronRightIcon />
                </Arrow>
              </FlexRow>
              <TogglesContent>
                <p>{t("igualdad2p3txt")}</p>
                <p>
                  <b>{t("igualdad2p3boldA")}</b>
                </p>
                <p>{t("igualdad2p3boldAtxt1")}</p>
                <p>{t("igualdad2p3boldAtxt2")}</p>
                <ul>
                  <li>{t("igualdad2p3boldAp1")}</li>
                  <li>{t("igualdad2p3boldAp2")}</li>
                  <li>{t("igualdad2p3boldAp3")}</li>
                </ul>
                <p>
                  <b>{t("igualdad2p3boldB")}</b>
                </p>
                <p>{t("igualdad2p3boldBtxt")}</p>
                <p>
                  <b>{t("igualdad2p3boldC")}</b>
                </p>
                <p>{t("igualdad2p3boldCtxt")}</p>
              </TogglesContent>
            </Toggles>
            <Toggles onClick={(e) => toggleClass(e)}>
              <FlexRow>
                <p>{t("igualdad2p4")}</p>
                <Arrow>
                  <ChevronRightIcon />
                </Arrow>
              </FlexRow>
              <TogglesContent>
                <p>{t("igualdad2p4txt")}</p>
                <p>
                  <b>{t("igualdad2p4boldA")}</b>
                </p>
                <p>{t("igualdad2p4boldAtxt")}</p>
                <p>
                  <b>{t("igualdad2p4boldB")}</b>
                </p>
                <p>{t("igualdad2p4boldBtxt")}</p>
                <ul>
                  <li>{t("igualdad2p4boldBp1")}</li>
                  <li>{t("igualdad2p4boldBp2")}</li>
                  <li> {t("igualdad2p4boldBp3")}</li>
                </ul>
                <p>
                  <b>{t("igualdad2p4boldC")}</b>
                </p>
                <p>{t("igualdad2p4boldCtxt")}</p>
                <ul>
                  <li>{t("igualdad2p4boldCp1")}</li>
                  <li>{t("igualdad2p4boldCp2")}</li>
                  <li>{t("igualdad2p4boldCp3")}</li>
                  <li>{t("igualdad2p4boldCp4")}</li>
                  <li>{t("igualdad2p4boldCp5")}</li>
                </ul>
                <p>
                  <b>{t("igualdad2p4boldD")}</b>
                </p>
                <p>{t("igualdad2p4boldDtxt")}</p>
                <p>
                  <b>{t("igualdad2p4boldE")}</b>
                </p>
                <p>{t("igualdad2p4boldEtxt1")}</p>
                <p>{t("igualdad2p4boldEtxt2")}</p>
              </TogglesContent>
            </Toggles>

            <Toggles onClick={(e) => toggleClass(e)}>
              <FlexRow>
                <p>{t("igualdad3")}</p>
                <Arrow>
                  <ChevronRightIcon />
                </Arrow>
              </FlexRow>
              <TogglesContent>
                <table>
                  <thead>
                    <tr>
                      <th className="white">A</th>
                      <th className="white">B</th>
                      <th className="white">C</th>
                      <th className="white">D</th>
                      <th className="white">E</th>
                      <th className="white">F</th>
                      <th className="white">G</th>
                      <th className="white">H</th>
                    </tr>
                    <tr>
                      <th>{t("igualdad3A")}</th>
                      <th>{t("igualdad3B")}</th>
                      <th>{t("igualdad3C")}</th>
                      <th>{t("igualdad3D")}</th>
                      <th colSpan={3}>{t("igualdad3EFG")}</th>
                      <th>{t("igualdad3H")}</th>
                    </tr>
                    <tr>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th className="white">2022</th>
                      <th className="white">2023</th>
                      <th className="white">2024</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td rowSpan={6}>
                        <b>{t("igualdad3A1")}</b>
                      </td>
                      <td rowSpan={6}>{t("igualdad3B1")}</td>
                      <td>{t("igualdad3C1")}</td>
                      <td>{t("igualdad3D1")}</td>
                      <td>
                        <CheckIcon />
                      </td>
                      <td></td>
                      <td></td>
                      <td> {t("igualdad3H1")}</td>
                    </tr>
                    <tr>
                      <td>{t("igualdad3C2")}</td>
                      <td>{t("igualdad3D2")}</td>
                      <td>
                        <CheckIcon />
                      </td>
                      <td></td>
                      <td></td>
                      <td>{t("igualdad3H2")}</td>
                    </tr>
                    <tr>
                      <td>{t("igualdad3C3")}</td>
                      <td>{t("igualdad3D3")} </td>
                      <td>
                        <CheckIcon />
                      </td>
                      <td></td>
                      <td></td>
                      <td>{t("igualdad3H3")}</td>
                    </tr>
                    <tr>
                      <td>{t("igualdad3C4")}</td>
                      <td>{t("igualdad3D4")}</td>
                      <td>
                        <CheckIcon />
                      </td>
                      <td></td>
                      <td></td>
                      <td>{t("igualdad3H4")}</td>
                    </tr>
                    <tr>
                      <td>{t("igualdad3C5")}</td>
                      <td>{t("igualdad3D5")}</td>
                      <td>
                        <CheckIcon />
                      </td>
                      <td></td>
                      <td></td>
                      <td>{t("igualdad3H5")}</td>
                    </tr>
                    <tr>
                      <td>{t("igualdad3C6")}</td>
                      <td>{t("igualdad3D6")}</td>
                      <td></td>
                      <td>
                        <CheckIcon />
                      </td>
                      <td></td>
                      <td>{t("igualdad3H6")}</td>
                    </tr>
                    <tr>
                      <td rowSpan={4}>
                        <b>{t("igualdad3A7")}</b>
                      </td>
                      <td>{t("igualdad3B11")}</td>
                      <td>{t("igualdad3C7")}</td>
                      <td>{t("igualdad3D7")}</td>
                      <td>
                        <CheckIcon />
                      </td>
                      <td></td>
                      <td></td>
                      <td>{t("igualdad3H7")}</td>
                    </tr>
                    <tr>
                      <td>{t("igualdad3B8")}</td>
                      <td>{t("igualdad3C8")}</td>
                      <td>{t("igualdad3D8")}</td>
                      <td>
                        <CheckIcon />
                      </td>
                      <td></td>
                      <td></td>
                      <td>{t("igualdad3H8")}</td>
                    </tr>
                    <tr>
                      <td rowSpan={2}>{t("igualdad3B9")}</td>
                      <td>{t("igualdad3C9")}</td>
                      <td>{t("igualdad3D9")}</td>
                      <td></td>
                      <td>
                        <CheckIcon />
                      </td>
                      <td></td>
                      <td>{t("igualdad3H9")}</td>
                    </tr>
                    <tr>
                      <td>{t("igualdad3C10")}</td>
                      <td>{t("igualdad3D10")}</td>
                      <td>
                        <CheckIcon />
                      </td>
                      <td></td>
                      <td></td>
                      <td>{t("igualdad3H10")}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>{t("igualdad3A11")}</b>
                      </td>
                      <td>{t("igualdad3B11")}</td>
                      <td>{t("igualdad3C11")}</td>
                      <td>{t("igualdad3D11")}</td>
                      <td>
                        <CheckIcon />
                      </td>
                      <td></td>
                      <td></td>
                      <td>{t("igualdad3H11")}</td>
                    </tr>
                    <tr>
                      <td rowSpan={3}>
                        <b>{t("igualdad3A12")}</b>
                      </td>
                      <td rowSpan={3}>{t("igualdad3B12")}</td>
                      <td>{t("igualdad3C12")}</td>
                      <td>{t("igualdad3D12")}</td>
                      <td>
                        <CheckIcon />
                      </td>
                      <td></td>
                      <td></td>
                      <td>{t("igualdad3H12")}</td>
                    </tr>
                    <tr>
                      <td>{t("igualdad3C13")}</td>
                      <td>{t("igualdad3D13")}</td>
                      <td>
                        <CheckIcon />
                      </td>
                      <td></td>
                      <td></td>
                      <td>{t("igualdad3H13")}</td>
                    </tr>
                    <tr>
                      <td>{t("igualdad3C14")}</td>
                      <td>{t("igualdad3D14")}</td>
                      <td></td>
                      <td>
                        <CheckIcon />
                      </td>
                      <td></td>
                      <td>{t("igualdad3H14")}</td>
                    </tr>
                  </tbody>
                </table>
              </TogglesContent>
            </Toggles>
            <h3>{t("online")}</h3>
            <p>
              <a
                href={language === 'pt' ? 
                  "https://seehealth-docs.s3.eu-central-1.amazonaws.com/Plano_para_a_igualdade_de_genero_PT.pdf":
                  "https://seehealth-docs.s3.eu-central-1.amazonaws.com/Plano_para_a_igualdade_de_genero_EN.pdf"
                }
                rel="noopener noreferrer"
                target="_blank"
              >
                {t("onlinetxt")}
              </a>
            </p>
          </InnerContent>
        </GenderPage>
      </PrivacySection>
    </PrivacyWrap>
  );
}

export default Privacy;
