import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styled from "styled-components";
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init();
const CardWrap = styled.div`
  max-width: 350px;
  box-sizing: border-box;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  @media (max-width: 1024px) {
    width: 100%;
  }
`;
const ImageWrap = styled.div`
  width: 100%;
  height: clamp(150px, 250px, 300px);
  overflow: hidden;
  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  color: #000;
  padding: 1.25rem;
  & p {
    color: var(--text);
  }
  & a {
    height: 50px;
    padding: 0.625rem 1.25rem;
    border-radius: 8px;
    border: 0;
    background: linear-gradient(169.85deg, #a8ddda 17.02%, #66b1ad 149.42%);
    color: var(--primary);
    font-weight: 600;
    margin-top: auto;
    box-sizing: border-box;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
  }
`;

function Card({ image, title, description, link }) {
  const { t } = useTranslation();
  return (
    <CardWrap
      data-aos="flip-right"
      data-aos-duration="1000"
      data-aos-offset="400"
    >
      <ImageWrap>
        <img src={image} alt="" />
      </ImageWrap>
      <CardContent>
        <h3>{title}</h3>
        <p>{description}</p>
        <Link to={link}>{t("sect3Btn")}</Link>
      </CardContent>
    </CardWrap>
  );
}

export default Card;
