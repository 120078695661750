import React from "react";
import { useTranslation } from "react-i18next";
import ProjectsContent from "../components/projectcontent";
import { AboutProject, AboutProjectReverse } from "../components/projectsabout";
import ProjectHero from "../components/projectshero";
import TechnicalSheet from "../assets/Ficha_Projeto_SeeApp.pdf";
import styled from "styled-components";
import coFunded from "../assets/img/projects/seeapp/cofunded.svg";

const Content = styled.div`
  max-width: 1366px;
  margin: 0 auto;
  @media (max-width: 1365px) {
    width: 90%;
  }
  @media (max-width: 1024px) {
    width: 90%;
    box-sizing: border-box;
    margin-bottom: 4rem;
  }
`;

function SeeApp() {
  const { t } = useTranslation();
  return (
    <>
      <ProjectHero
        title={"SEE App"}
        banner={require("../assets/img/projects/seeapp/bannerseeapp.png")}
      />
      <ProjectsContent
        quickinfoTitle={t("SeeAppSub")}
        quickinfoContent={t("SeeAppInfo")}
        quickinfoLink={"www.seeapp.pt"}
      >
        <AboutProject
          aboutTitle={t("SeeAppTitle1")}
          aboutContent={t("SeeAppTxt1")}
          aboutImage={require("../assets/img/projects/seeapp/seeapp_2.png")}
        />
        <AboutProjectReverse
          aboutTitle={t("SeeAppTitle2")}
          aboutContent={t("SeeAppTxt2")}
          aboutImage={require("../assets/img/projects/seeapp/seeapp_3.png")}
        />
        <AboutProject
          aboutTitle={t("SeeAppTitle3")}
          aboutContent={t("SeeAppTxt3")}
          aboutImage={require("../assets/img/projects/seeapp/seeapp_1.png")}
        />

        <Content>
          <h2>{t("SeeAppFT")}</h2>
          <div>
            <ul>
              <li>{t("SeeAppFT1")}</li>
              <li>{t("SeeAppFT2")}</li>
              <li>{t("SeeAppFT3")}</li>
              <li>{t("SeeAppFT4")}</li>
              <li>{t("SeeAppFT5")}</li>
              <li>{t("SeeAppFT6")}</li>
              <li>{t("SeeAppFT7")}</li>
              <li>{t("SeeAppFT8")}</li>
              <li>{t("SeeAppFT9")}</li>
            </ul>
            <p
              style={{
                margin: "0 20px",
                fontWeight: "600",
                fontSize: "18px",
              }}
            >
              {t("SeeAppFTConsult")}{" "}
              <a
                href={TechnicalSheet}
                rel="noopener noreferrer"
                target={"_blank"}
                style={{
                  textDecoration: "underline",
                  cursor: "pointer",
                  color: "inherit",
                }}
              >
                {t("SeeAppFTConsult2")}
              </a>
            </p>
          </div>
          <div>
            <p>{t("SeeAppFT10")}</p>
            <img src={coFunded} alt="" width="100%" />
          </div>
        </Content>
      </ProjectsContent>
    </>
  );
}

export default SeeApp;
