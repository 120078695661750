import React from "react";
import styled from "styled-components";
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init();
const CardWrap = styled.div`
  width: 31%;
  max-height: 12.5rem;
  padding: 4rem 3.125rem;
  text-align: center;
  box-sizing: border-box;
  border-radius: 8px;
  background: var(--primary);
  background: linear-gradient(169.85deg, #5cc7cf 17.02%, #317180 149.42%);
  position: relative;
  border: 2px solid #e0f3f0;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
  @media (max-width: 1024px) {
    margin: 3rem 0;
    width: 100%;
  }
`;
const CircleBorders = styled.div`
  content: " ";
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 120px;
  position: absolute;
  top: -85px;
  left: 50%;
  transform: translate(-50%, 0);
  border-radius: 50%;
  border: 8px solid var(--primary);
  background-color: #c3eef0;

  & img {
    width: 70%;
    height: 70%;
  }
`;
const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  & h4 {
    color: var(--primary);
  }
  & a {
    font-size: 1rem;
    color: var(--primary);
    text-decoration: none;
  }
`;

function ContactCard({ image, title, caption, href }) {
  return (
    <CardWrap data-aos="fade-left">
      <CircleBorders>
        <img src={image} alt="" />
      </CircleBorders>
      <CardContent>
        <h4>{title}</h4>
        <a href={href} target="_blank" rel="noreferrer noopenner">{caption}</a>
      </CardContent>
    </CardWrap>
  );
}

export default ContactCard;
