import React from "react";
import styled from "styled-components";
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init();
const ButtonWrap = styled.div.attrs((props) => ({ className: "item" }))`
  position: relative;
  flex-grow: 1;
  width: 30%;
  height: 100%;
  flex-grow: 1;
  background-color: var(--primary);
  border-radius: 15px;
  box-sizing: border-box;
  box-shadow: 0px 20px 40px rgba(36, 80, 83, 0.14);
  padding: 1.25rem;
  cursor: pointer;
  overflow: hidden;
  &::before {
    content: " ";
    display: block;
    height: 200px;
    width: 200px;
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%) scale(0);
    background: linear-gradient(169.85deg, #5cc7cf 17.02%, #317180 149.42%);
    border-radius: 50%;
    opacity: 0;
    z-index: 0;
    transition: 0.3s ease-in-out;
  }
  &:hover {
    &::before {
      transform: translate(-50%, -50%) scale(4);
      opacity: 1;
    }
    & h4 {
      color: var(--primary);
    }
    & img {
      animation: shake-vertical 10s cubic-bezier(0.455, 0.03, 0.515, 0.955)
        infinite both;
    }
  }
  @media (max-width: 1024px) {
    box-sizing: border-box;
    width: 100%;
    flex-direction: column;
    padding: 0 1.25rem;
  }
`;
const ButtonContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  z-index: 1;
  & h4 {
    font-weight: 600;
    margin-top: auto;
    color: #035561;
    transition: 0.2s;
    text-align: center;
  }
`;
const ImageWrap = styled.div`
  width: 200px;
  height: 200px;
  text-align: center;
`;
const ServiceImage = styled.img`
  height: 100%;
  width: 100%;
  object-fit: contain;
`;

function ButtonPopUp({ id, name, image, onClick }) {
  return (
    <ButtonWrap
      id={id}
      onClick={onClick}
      data-aos="zoom-out"
      data-aos-duration="500"
    >
      <ButtonContent>
        <ImageWrap>
          <ServiceImage src={image} alt="" />
        </ImageWrap>
        <h4>{name}</h4>
      </ButtonContent>
    </ButtonWrap>
  );
}

export default ButtonPopUp;
