import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Card from "./card";

const ProjectsWrap = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 2.5rem 0;
  margin: 10rem 0;
`;
const ProjectsSection = styled.div`
  max-width: 1366px;
  margin: 0 auto;
  color: var(--primary);
  & h2 {
    color: #469aa6;
    margin-bottom: 2.5rem;
  }
  @media (max-width: 1365px) {
    width: 90%;
  }
  @media (max-width: 1024px) {
    box-sizing: border-box;
    width: 90%;
    flex-direction: column;
    padding: 0 1.25rem;
  }
`;
const CardSection = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-wrap: wrap;
  gap: 2.5rem;
  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: center;
  }
`;

function Projects() {
  const { t } = useTranslation();
  return (
    <ProjectsWrap id={"projects"}>
      <ProjectsSection>
        <h2>{t("tab3")}</h2>
        <CardSection>
          <Card
            image={require("../assets/img/projects/Madeira_Safe_thumbnail.png")}
            link={"/madeirasafe"}
            title={"Madeira Safe"}
            description={t("sect3opt1Txt")}
          />
          <Card
            image={require("../assets/img/projects/My_eHealth_thumbnail.png")}
            link={"/myehealth"}
            title={"My eHealth"}
            description={t("sect3opt3Txt")}
          />
          <Card
            image={require("../assets/img/projects/SeeApp_thumbnail.png")}
            link={"/seeapp"}
            title={"Saúde Escolas"}
            description={t("sect3opt2Txt")}
          />
        </CardSection>
      </ProjectsSection>
    </ProjectsWrap>
  );
}

export default Projects;
