import React, { useEffect } from "react";
import styled from "styled-components";
import jQuery from "jquery";
import { useTranslation } from "react-i18next";
import images from "../components/images";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import globulo from "../assets/img/general/hero/globule.png";
import particula from "../assets/img/general/hero/particle.png";

const HeroCurve = styled.div`
  width: 100%;
  height: 100vh;
  position: relative;
  background: linear-gradient(169.85deg, #a8ddda 17.02%, #66b1ad 149.42%);
  &::before {
    content: " ";
    display: block;
    width: 100%;
    height: 100%;
    bottom: -20%;
    position: absolute;
    background-color: var(--secondary);
    background: linear-gradient(169.85deg, #a3dad7 11.02%, #66b1ad 149.42%);
    clip-path: ellipse(80% 40% at 50% 50%);
    z-index: 0;
  }
  &::after {
    content: " ";
    display: block;
    width: 100%;
    height: 100%;
    bottom: -22%;
    position: absolute;
    background-color: var(--details);
    clip-path: ellipse(80% 40% at 60% 50%);
    z-index: -1;
  }
`;
const HeroWrap = styled.div`
  height: 100%;
  max-width: 1366px;
  margin: 0 auto;
  position: relative;
  @media (max-width: 1365px) {
    width: 100%;
  }
  @media (max-width: 1025px) {
    margin: 0;
    width: 100%;
    overflow: hidden;
  }
`;
const Caption = styled.div`
  position: absolute;
  width: 50%;
  top: 50%;
  transform: translate(0, -50%);
  animation: HeroTextIntro 1.5s 1 ease-in-out;
  color: var(--primary);
  & h1 {
    white-space: pre-wrap;
    mix-blend-mode: normal;
    text-shadow: 0px 0px 8px rgba(255, 255, 255, 0.38);
  }
  @media (max-width: 1365px) {
    width: 60%;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    box-sizing: border-box;
    z-index: 1;
    padding: 0 1.25rem;
    & h1 {
      font-size: 2.5rem;
    }
  }
  @media (max-width: 768px) {
    width: 80%;
  }
`;
const CaptionImage = styled.div`
  position: absolute;
  top: 50%;
  width: 50%;
  right: 0;
  transform: translate(0, -50%);
  animation: heartIntro 1.5s 1 ease-in-out;

  & .carousel {
    padding: 0;
    margin: 0;
  }
  @media (max-width: 768px) {
    width: 100%;
    position: absolute;
    top: 0;

    opacity: 0.5;
    z-index: 0;
    transform: unset;
    box-sizing: border-box;
    padding: 0 1.25rem;
    animation: unset;
  }
`;
const ParticleContainer = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  width: 40px;
  height: 40px;
  transform: translate(-50%, -50%);
  z-index: -1;

  @media (max-width: 1025px) {
    transform: translate(50%, -50%);
    left: 100%;
  }
`;
function Hero() {
  let imagesArray = [
    images.image01,
    images.image02,
    images.image03,
    images.image04,
    images.image05,
    images.image06,
    images.image07,
    images.image08,
    images.image09,
    images.image10,
    images.image11,
    images.image12,
    images.image13,
    images.image14,
    images.image15,
    images.image16,
    images.image17,
    images.image18,
    images.image19,
    images.image20,
    images.image21,
    images.image22,
    images.image23,
    images.image24,
    images.image25,
    images.image26,
    images.image27,
    images.image28,
    images.image29,
  ];
  useEffect(() => {
    jQuery(document).ready(function () {
      jQuery(".cont").css({
        position: "relative",
      });
      jQuery(".carousel li:last-child").prependTo(".carousel");

      setInterval(function () {
        next_slide();
      }, 100);
      function next_slide() {
        jQuery(".carousel").animate({}, 200, function () {
          jQuery(".carousel li:last-child").prependTo(".carousel");
          jQuery(".carousel").css("left", 0);
        });
      }
    });
  }, []);
  const { t } = useTranslation();
  const particlesInit = async (main) => {
    await loadFull(main);
  };

  const particlesLoaded = (container) => {};
  return (
    <HeroCurve>
      <Particles
        id="tsparticles"
        init={particlesInit}
        loaded={particlesLoaded}
        options={{
          background: {
            color: {
              value: "transparent",
            },
          },
          fullScreen: { enable: false },
          fpsLimit: 120,
          interactivity: {
            events: {
              onClick: {
                enable: false,
                mode: "push",
              },
              onHover: {
                enable: false,
                mode: "repulse",
              },
              resize: true,
            },
            modes: {
              push: {
                quantity: 4,
              },
              repulse: {
                distance: 200,
                duration: 0.4,
              },
            },
          },
          particles: {
            color: {
              value: "#ffffff",
            },
            links: {
              color: "#ffffff",
              distance: 150,
              enable: false,
              opacity: 0.5,
              width: 1,
            },
            collisions: {
              enable: false,
            },
            move: {
              direction: "none",
              enable: true,
              outModes: {
                default: "bounce",
              },
              random: false,
              speed: 1,
              straight: false,
            },
            number: {
              density: {
                enable: true,
                area: 4000,
              },
              value: 80,
            },
            opacity: {
              value: 1,
            },
            shape: {
              type: "image",
              stroke: {
                width: 0,
                color: "#000000",
              },
              polygon: {
                nb_sides: 5,
              },
              images: [
                {
                  src: globulo,
                  width: 100,
                  height: 100,
                },
                {
                  src: particula,
                  width: 100,
                  height: 100,
                },
              ],
            },
            size: {
              value: { min: 10, max: 20 },
            },
          },
          detectRetina: true,
        }}
      />
      <HeroWrap>
        <Caption>
          <h1>{t("homeTitle")}</h1>
          <h4>{t("homeText")}</h4>
        </Caption>
        <CaptionImage>
          <div className="cont">
            <ul className="carousel">
              {imagesArray.map((image, index) => (
                <li key={index}>
                  <img src={image} alt="" />
                </li>
              ))}
            </ul>
            <ParticleContainer>
              <div className="particles" />
              <div className="particles" />
              <div className="particles" />
              <div className="particles" />
              <div className="particles" />
              <div className="particles" />
              <div className="particles" />
              <div className="particles" />
              <div className="particles" />
              <div className="particles" />
            </ParticleContainer>
          </div>
        </CaptionImage>
      </HeroWrap>
    </HeroCurve>
  );
}

export default Hero;
