import React, { useEffect, useState } from "react";
import styled from "styled-components";
import jQuery from "jquery";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const NavigationWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  @media (max-width: 1024px) {
    justify-content: center;
    flex-direction: column;
    gap: 2.5rem;
  }
`;
const BackButtonWrap = styled.div`
  display: flex;
  gap: 1.25rem;
  & a {
    text-decoration: none;
  }
`;
const BackButton = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 200px;
  color: var(--primary);
  & span {
    margin-left: 50px;
  }
  & svg {
    position: absolute;
    left: 1%;
    color: var(--secondary);
    z-index: 1;
  }
  &::after {
    content: " ";
    position: absolute;
    z-index: 0;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background-color: var(--primary);
    left: 0;
    font-size: 1rem;
  }
`;
const BreadCrumbs = styled.div`
  display: flex;
  gap: 1.25rem;
  align-items: center;
  & a,
  svg {
    text-decoration: none;
    color: var(--primary);
    opacity: 0.7;
    &.active {
      opacity: 1;
    }
  }
  @media (max-width: 1024px) {
    align-self: center;
  }
`;

function NavigationProjects() {
  const { t } = useTranslation();
  const [website, SetWebsite] = useState("");

  useEffect(() => {
    if (jQuery(document.location).attr("pathname").includes("myehealth")) {
      SetWebsite("My eHealth");
    } else if (jQuery(document.location).attr("pathname").includes("seeapp")) {
      SetWebsite("See App");
    } else if (
      jQuery(document.location).attr("pathname").includes("madeirasafe")
    ) {
      SetWebsite("Madeira Safe");
    }
  }, []);
  return (
    <NavigationWrap>
      <BackButtonWrap>
        <Link to="/">
          <BackButton>
            <KeyboardArrowLeftIcon />
            <span>{t("goback")}</span>
          </BackButton>
        </Link>
      </BackButtonWrap>
      <BreadCrumbs>
        <Link to="/">{t("home")}</Link>
        <KeyboardArrowRightIcon />
        <a href="/#projects">{t("tab3")}</a>
        <KeyboardArrowRightIcon />
        <span className="active">{website}</span>
      </BreadCrumbs>
    </NavigationWrap>
  );
}

export default NavigationProjects;
