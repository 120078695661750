import React from "react";
import { useTranslation } from "react-i18next";
import ProjectsContent from "../components/projectcontent";
import { AboutProject, AboutProjectReverse } from "../components/projectsabout";
import ProjectHero from "../components/projectshero";

function MyHealth() {
  const { t } = useTranslation();
  return (
    <>
      <ProjectHero
        title={"MyeHealth"}
        banner={require("../assets/img/projects/myehealth/bannerhealth.png")}
      />
      <ProjectsContent
        quickinfoTitle={t("eHealthSub")}
        quickinfoContent={t("eHealthInfo")}
        quickinfoLink={"www.myehealth.eu"}
      />
      <AboutProject
        aboutTitle={t("eHealthTitle1")}
        aboutContent={t("eHealthTxt1")}
        aboutImage={require("../assets/img/projects/myehealth/myehealth_1.png")}
      />
      <AboutProjectReverse
        aboutTitle={t("eHealthTitle2")}
        aboutContent={t("eHealthTxt2")}
        aboutImage={require("../assets/img/projects/myehealth/myehealth_2.png")}
      />
      <AboutProject
        aboutTitle={t("eHealthTitle3")}
        aboutContent={t("eHealthTxt3")}
        aboutImage={require("../assets/img/projects/myehealth/myehealth_3.png")}
      />
      <AboutProjectReverse
        aboutTitle={t("eHealthTitle4")}
        aboutContent={t("eHealthTxt4")}
        aboutImage={require("../assets/img/projects/myehealth/myehealth_4.png")}
      />
    </>
  );
}

export default MyHealth;
