import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import MapIcon from "../assets/img/contacts/pin_icon.png";
import EmailIcon from "../assets/img/contacts/mail.png";
import PhoneIcon from "../assets/img/contacts/ring.png";
import ContactImg from "../assets/img/contacts/contact.png";
import { useForm } from "react-hook-form";
import emailjs from "@emailjs/browser";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const HeroWrap = styled.div`
  width: 100%;
  min-height: 70vh;
  display: flex;
  flex-direction: column;
  position: relative;
  box-sizing: border-box;
  padding-top: 120px;
  @media (max-width: 768px) {
    height: 100vh;
    box-sizing: border-box;
  }
  &::before {
    content: "";
    position: absolute;
    width: 100%;
    min-height: 65vh;
    left: 0;
    top: 0;
    z-index: -1;
    background: linear-gradient(169.85deg, #a8ddda 17.02%, #66b1ad 149.42%);

    @media (max-width: 768px) {
      min-height: 100vh;
    }
  }
`;

const HeroSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  h1 {
    color: white;
  }
`;

const ContactsCardSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2rem;
  margin: 6.25rem 0;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const CircleBorders = styled.div`
  content: " ";
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 120px;
  position: absolute;
  top: -85px;
  left: 50%;
  transform: translate(-50%, 0);
  border-radius: 50%;
  background-color: #fbfbfb;
  @media (max-width: 768px) {
    width: 100px;
    height: 100px;
  }
  & img {
    width: 70%;
    height: 70%;
    @media (max-width: 768px) {
      width: 50%;
      height: 50%;
    }
  }
`;

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
  & h4 {
    color: var(--primary);
  }
  & a {
    font-size: 1rem;
    color: var(--primary);
    text-decoration: none;
    text-align: center;
  }
  @media (max-width: 768px) {
    margin-top: 10px;
    margin-bottom: 30px;
  }
`;

const Card = styled.div`
  position: relative;
  box-sizing: border-box;
  padding: 2.5rem 0;
  width: 250px;
`;

const AboutSectionReversedimg = styled.div`
  width: 100%;
  /* height: 450px; */
  position: relative;
  @media (max-width: 1025px) {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    gap: 2.5rem;
    flex-direction: column;
  }
`;

const ImageSectionBGReversed = styled.div`
  position: relative;
  height: 100%;
  max-height: 500px;
  top: 0;
  right: 0;
  & img {
    height: 100%;
    width: 100%;
    object-fit: contain;
    position: relative;
    right: 0;
  }
  @media (max-width: 1025px) {
    position: relative;
    & img {
      display: none;
    }
  }
`;

const AboutWrap = styled.div`
  width: 100%;
  margin-bottom: 5rem;
`;

const AboutContent = styled.div`
  position: relative;
  z-index: 1;
  /* max-width: 1366px; */
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2.5rem;
  @media (max-width: 1364px) {
    box-sizing: border-box;
    /* width: 90%; */
    gap: 1.125rem;
    padding-left: 50px;
  }
  @media (max-width: 1024px) {
    box-sizing: border-box;
    width: 90%;
    flex-direction: column;
    gap: 1.125rem;
    padding-left: 0px;
  }
`;

const TextSection = styled.div`
  width: 75%;
  position: relative;
  margin: 2.5rem;
  & h2 {
    color: #317180;
    font-weight: 600;
    font-size: 32px;
    margin-top: 2.5rem;
    line-height: 48px;
  }
  @media (max-width: 1366px) {
    margin: 0 auto;
    width: 100%;
  }
`;

const FormTitle = styled.p`
  color: #317180;
  font-size: 18px;
  font-weight: 500;
  line-height: 30px;
  margin-bottom: 0.8rem;
`;

const FormInput = styled.div`
  background: #fbfbfb;
  border: 1px solid #e2e8f080;
  border-radius: 6px;
  min-height: 45px;
  width: 100%;

  & input,
  select {
    outline: 0;
    border: 0;
    height: 45px;
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
    background: #fbfbfb;

    &:focus {
      box-shadow: 0px 0px 6px rgba(7, 20, 99, 0.5);
    }
    &::placeholder {
      color: #a0a8b1;
    }
  }

  & textarea {
    width: 100%;
    resize: none;
    border: 0;
    outline: 0;
    padding: 10px 20px;
    box-sizing: border-box;
    &:focus {
      border: 0.792793px solid rgba(7, 20, 99, 0.5);
      box-shadow: 0px 0px 6px rgba(7, 20, 99, 0.5);
    }
    &::placeholder {
      color: #a0a8b1;
    }
  }
`;

const FormRow = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  width: 100%;
  flex-wrap: nowrap;
  @media (max-width: 1024px) {
    flex-wrap: wrap;
  }
`;

const FormButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 20px;
  & button {
    background: linear-gradient(169.85deg, #a8ddda 17.02%, #66b1ad 149.42%);
    border-radius: 8px;
    color: white;
    padding: 8px 16px;
    border: none;
    font-size: 18px;
    cursor: pointer;
  }
  @media (max-width: 768px) {
    justify-content: center;
  }
`;

function Contacts() {
  const { t } = useTranslation();
  const { register, handleSubmit } = useForm();

  const form = useRef();

  const sendEmail = (data) => {
    emailjs
      .send("service_30assso", "template_ng666jk", data, "qiaS-GsxWs7mieOEA")
      .then(
        (result) => {
          toast("Email sent successfully");
          form.current.reset();
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <>
      <HeroWrap>
        <HeroSection>
          <h1>{t("tab5")}</h1>
          <div style={{ maxWidth: "1366px", margin: "0 auto" }}>
            <ContactsCardSection>
              <Card>
                <CircleBorders>
                  <img src={MapIcon} alt="Map icon" />
                </CircleBorders>
                <CardContent>
                  <h4>{t("sect5opt1")}</h4>
                  <a
                    href={
                      "https://www.google.pt/maps/search/Rua+Dr.+Fern%C3%A3o+de+Ornelas+n%C2%BA56,+4%C2%BA+AC+9050-021+Funchal/@32.6489753,-16.9075309,17z/data=!3m1!4b1?hl=pt-PT"
                    }
                    target="_blank"
                    rel="noreferrer noopenner"
                  >
                    {t("sect5opt1Txt")}
                  </a>
                </CardContent>
              </Card>
              <Card>
                <CircleBorders>
                  <img src={EmailIcon} alt="Email icon" />
                </CircleBorders>
                <CardContent>
                  <h4>{t("sect5opt2")}</h4>
                  <a
                    href={`mailto:${t("sect5opt2Txt")}`}
                    target="_blank"
                    rel="noreferrer noopenner"
                  >
                    {t("sect5opt2Txt")}
                  </a>
                </CardContent>
              </Card>
              <Card>
                <CircleBorders>
                  <img src={PhoneIcon} alt="Email icon" />
                </CircleBorders>
                <CardContent>
                  <h4>{t("sect5opt3")}</h4>
                  <a
                    href={`tel:${t("sect5opt3Txt")}`}
                    target="_blank"
                    rel="noreferrer noopenner"
                  >
                    {t("sect5opt3Txt")}
                  </a>
                </CardContent>
              </Card>
            </ContactsCardSection>
          </div>
        </HeroSection>
      </HeroWrap>

      <AboutWrap>
        <AboutSectionReversedimg>
          <AboutContent>
            <TextSection>
              <h2>{t("contactUs")}</h2>
              <p>{t("contactUs_subtitle")}</p>

              <div>
                <form
                  style={{ display: "flex", flexDirection: "column" }}
                  ref={form}
                  onSubmit={handleSubmit(sendEmail)}
                >
                  <FormRow>
                    <div style={{ width: "100%" }}>
                      <FormTitle>
                        {t("contactUs_form_firstname")}{" "}
                        <span style={{ color: "red", fontSize: "18px" }}>
                          *
                        </span>
                      </FormTitle>
                      <FormInput>
                        <input
                          type={"text"}
                          placeholder={t("contactUs_form_placeholder_1")}
                          name="first_name"
                          {...register("first_name", { required: true })}
                        />
                      </FormInput>
                    </div>
                    <div style={{ width: "100%" }}>
                      <FormTitle>
                        {t("contactUs_form_lastname")}{" "}
                        <span style={{ color: "red", fontSize: "18px" }}>
                          *
                        </span>
                      </FormTitle>
                      <FormInput>
                        <input
                          type={"text"}
                          name="last_name"
                          placeholder={t("contactUs_form_placeholder_1")}
                          {...register("last_name", { required: true })}
                        />
                      </FormInput>
                    </div>
                  </FormRow>

                  <FormRow>
                    <div style={{ width: "100%" }}>
                      <FormTitle>
                        {t("contactUs_form_email")}{" "}
                        <span style={{ color: "red", fontSize: "18px" }}>
                          *
                        </span>
                      </FormTitle>
                      <FormInput>
                        <input
                          type={"email"}
                          placeholder={t("contactUs_form_placeholder_1")}
                          name="from_email"
                          {...register("from_email", { required: true })}
                        />
                      </FormInput>
                    </div>
                    <div style={{ width: "100%" }}>
                      <FormTitle>
                        {t("contactUs_form_phoneNumber")}{" "}
                        <span style={{ color: "red", fontSize: "18px" }}>
                          *
                        </span>
                      </FormTitle>
                      <FormInput>
                        <input
                          type={"text"}
                          name="phone_number"
                          {...register("phone_number", { required: true })}
                          placeholder={t("contactUs_form_placeholder_1")}
                        />
                      </FormInput>
                    </div>
                  </FormRow>

                  <FormRow>
                    <div style={{ width: "100%" }}>
                      <FormTitle>{t("contactUs_form_subject")}</FormTitle>
                      <FormInput>
                        <select
                          id="select-input"
                          name="subject"
                          {...register("subject", { required: true })}
                        >
                          <option value="">
                            {t("contactUs_form_placeholder_2")}
                          </option>
                          <option value="software">
                            {t("contactUs_form_subject_option_1")}
                          </option>
                          <option value="design">
                            {t("contactUs_form_subject_option_2")}
                          </option>
                          <option value="customer_support">
                            {t("contactUs_form_subject_option_3")}
                          </option>
                          <option value="quality_control">
                            {t("contactUs_form_subject_option_4")}
                          </option>
                          <option value="multi_platform">
                            {t("contactUs_form_subject_option_5")}
                          </option>
                          <option value="app_deployment">
                            {t("contactUs_form_subject_option_6")}
                          </option>
                        </select>
                      </FormInput>
                    </div>
                    <div style={{ width: "100%" }}>
                      <FormTitle>{t("contactUs_form_interest")}</FormTitle>
                      <FormInput>
                        <select
                          id="select-input"
                          name="interest_service"
                          {...register("interest_service", { required: true })}
                        >
                          <option value="">
                            {t("contactUs_form_placeholder_2")}
                          </option>
                          <option value="budget">
                            {t("contactUs_form_interest_option_1")}
                          </option>
                          <option value="information">
                            {t("contactUs_form_interest_option_2")}
                          </option>
                          <option value="other">
                            {t("contactUs_form_interest_option_3")}
                          </option>
                        </select>
                      </FormInput>
                    </div>
                  </FormRow>
                  <FormRow>
                    <div style={{ width: "100%" }}>
                      <FormTitle>{t("contactUs_form_message")}</FormTitle>
                      <FormInput>
                        <textarea
                          rows={5}
                          placeholder={t("contactUs_form_placeholder_3")}
                          name="message"
                          {...register("message", { required: true })}
                        />
                      </FormInput>
                    </div>
                  </FormRow>

                  <FormRow>
                    <div style={{ width: "100%" }}>
                      <FormButton>
                        <button type="submit">{t("form_submit")}</button>
                      </FormButton>
                    </div>
                  </FormRow>
                  <ToastContainer
                    position="bottom-center"
                    autoClose={4000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable={false}
                    pauseOnHover
                    theme="dark"
                  />
                </form>
              </div>
            </TextSection>
            <ImageSectionBGReversed>
              <img src={ContactImg} alt="Side customizable" />
            </ImageSectionBGReversed>
          </AboutContent>
        </AboutSectionReversedimg>
      </AboutWrap>
    </>
  );
}

export default Contacts;
