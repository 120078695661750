import React, { useEffect } from "react";
import styled from "styled-components";
import jQuery from "jquery";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

const ButtonTop = styled.div.attrs((props) => ({ id: "back" }))`
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  position: fixed;
  bottom: 10%;
  right: 10%;
  background-color: #59c0c8;
  color: #fff;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  opacity: 0;
  transition: 0.3s;
`;

function BackToTop() {
  useEffect(() => {
    jQuery(window).scroll(function (event) {
      var scroll = jQuery(window).scrollTop();
      if (scroll > 500) {
        jQuery("#back").css({ visibility: "visible", opacity: "1" });
      } else {
        jQuery("#back").css({ visibility: "hidden", opacity: "0" });
      }
    });
  });
  function GoBack() {
    window.scrollTo(0, 0);
  }
  return (
    <ButtonTop onClick={GoBack}>
      <ExpandLessIcon />
    </ButtonTop>
  );
}

export default BackToTop;
