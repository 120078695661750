import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import InfoBox from "./infobox";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import globulo from "../assets/img/general/hero/globule.png";
import particula from "../assets/img/general/hero/particle.png";

const CareersWrap = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  box-sizing: border-box;
  padding: 2.5rem 0;
  background: linear-gradient(169.85deg, #a8ddda 17.02%, #66b1ad 149.42%);
`;
const CareersSection = styled.div`
  max-width: 1366px;
  margin: 8rem auto;
  color: var(--primary);
  position: relative;
  z-index: 1;
  & h2 {
    color: #fff;
    margin-bottom: 2.5rem;
  }
  @media (max-width: 1365px) {
    width: 90%;
  }
  @media (max-width: 1024px) {
    box-sizing: border-box;
    width: 90%;
    flex-direction: column;
    padding: 0 1.25rem;
  }
`;
const InfoBoxSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 2.5rem;
  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;
function CareerSection() {
  const { t } = useTranslation();
  const particlesInit = async (main) => {
    await loadFull(main);
  };

  const particlesLoaded = (container) => {};
  return (
    <CareersWrap id={"careers"}>
      <Particles
        id="tsparticles2"
        init={particlesInit}
        loaded={particlesLoaded}
        options={{
          background: {
            color: {
              value: "transparent",
            },
          },
          fullScreen: { enable: false },
          fpsLimit: 120,
          interactivity: {
            events: {
              onClick: {
                enable: false,
                mode: "push",
              },
              onHover: {
                enable: false,
                mode: "repulse",
              },
              resize: true,
            },
            modes: {
              push: {
                quantity: 4,
              },
              repulse: {
                distance: 200,
                duration: 0.4,
              },
            },
          },
          particles: {
            color: {
              value: "#ffffff",
            },
            links: {
              color: "#ffffff",
              distance: 150,
              enable: false,
              opacity: 0.5,
              width: 1,
            },
            collisions: {
              enable: false,
            },
            move: {
              direction: "none",
              enable: true,
              outModes: {
                default: "bounce",
              },
              random: false,
              speed: 1,
              straight: false,
            },
            number: {
              density: {
                enable: true,
                area: 4000,
              },
              value: 80,
            },
            opacity: {
              value: 1,
            },
            shape: {
              type: "image",
              stroke: {
                width: 0,
                color: "#000000",
              },
              polygon: {
                nb_sides: 5,
              },
              images: [
                {
                  src: globulo,
                  width: 100,
                  height: 100,
                },
                {
                  src: particula,
                  width: 100,
                  height: 100,
                },
              ],
            },
            size: {
              value: { min: 10, max: 20 },
            },
          },
          detectRetina: true,
        }}
      />
      <CareersSection>
        <h2>{t("tab4")}</h2>
        <InfoBoxSection>
          {/* <InfoBox
            title={t("sect4opt1")}
            description={t("sect4opt1Txt1") + t("sect4opt1Txt2")}
          />
          <InfoBox title={t("sect4opt3")} description={t("sect4opt3Txt")} /> */}
          <InfoBox title={t("sect4opt2")} description={t("sect4opt2Txt")} />
        </InfoBoxSection>
      </CareersSection>
    </CareersWrap>
  );
}

export default CareerSection;
