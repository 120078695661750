import React from "react";
import styled from "styled-components";
import { ReactComponent as Logo } from "../assets/img/general/seehealth_vertical.svg";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { useTranslation } from "react-i18next";
import moment from "moment";

const FooterWrap = styled.div`
  width: 100%;
  background: linear-gradient(169.85deg, #a8ddda 17.02%, #66b1ad 149.42%);
  box-sizing: border-box;
  padding: 1rem 0;
`;
const FooterSection = styled.div`
  width: 70%;
  margin: 0 auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  @media (max-width: 1024px) {
    box-sizing: border-box;
    width: 100%;
    flex-direction: column;
    padding: 0 1.25rem;
  }
  & small {
    color: var(--primary);
  }
`;
const FooterSocial = styled.div`
  display: flex;
  justify-content: center;
  gap: 1.25rem;
`;
const FooterLinksWrap = styled.div`
  box-sizing: border-box;
  padding-bottom: 1.25rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 1.25rem;
  border-bottom: 1px solid #ffffff33;
  & a {
    text-decoration: none;
    color: var(--primary);
    font-weight: 400;
    opacity: 0.7;
    cursor: pointer;
    transition: 0.3s;
  }
  & a:hover {
    opacity: 1;
  }
  @media (max-width: 1024px) {
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
  }
`;

function Footer() {
  const { t } = useTranslation();
  return (
    <FooterWrap>
      <FooterSection>
        <Logo />
        <small>{t("footerText")}</small>
        <FooterLinksWrap>
          <a href="/privacy/#privacy">{t("privacyPolicy")}</a>
          <a href="/privacy/#terms">{t("termsUse")}</a>
          <a href="/privacy/#gender">{t("igualdade")}</a>
          <a href="/reports">{t("reports")}</a>
          <a
            href="https://www.livroreclamacoes.pt/Inicio/"
            target={"_blank"}
            rel="noopener noreferrer"
          >
            {t("complaint")}
          </a>
        </FooterLinksWrap>
        <FooterLinksWrap>
          <FooterSocial>
            <a
              alt="facebook"
              title="Rede Social Facebook" 
              href="https://www.facebook.com/seehealth"
            >
              <FacebookIcon fontSize="large" />
            </a>
            <a
              alt="instagram"
              title="Rede Social Instagram" 
              href="https://www.instagram.com/sh_seehealth/"
            >
              <InstagramIcon fontSize="large" />
            </a>
            <a
              alt="linkedin"
              title="Rede Social Linkedin" 
              href="https://www.linkedin.com/company/seehealth/"
            >
              <LinkedInIcon fontSize="large" />
            </a>
          </FooterSocial>
        </FooterLinksWrap>
        <small>
          © {moment().format('YYYY')} {t("company")} {t("rights")}
        </small>
      </FooterSection>
    </FooterWrap>
  );
}

export default Footer;
