import React, { useState, useEffect } from "react";
import styled from "styled-components";
import CookieConsent, { getCookieConsentValue } from "react-cookie-consent";
import Checkbox from "@mui/material/Checkbox";

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
`;

const ModalTitle = styled.h1`
  position: absolute;
  top: 10px;
  left: 15px;
  margin-top: 0;
  font-size: 1.5rem;
  font-weight: 600;
`;

const ModalContent = styled.div`
  position: relative;
  background-color: #fff;
  padding: 25px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  max-width: 550px;
  width: 100%;
  text-align: justify;
`;

const CookieOptions = styled.div`
  display: flex;
  align-items: start;
`;

const buttonStyle = {
  minWidth: "4rem",
  height: "40px",
  fontSize: "1.125rem",
  padding: "0.625 1.25rem",
  borderRadius: "8px",
  border: "0",
  background: "linear-gradient(169.85deg,#a8ddda 17.02%, #66b1ad 149.42%)",
  color: "white",
  fontWeight: "600",
  cursor: "pointer",
  paddingLeft: "15px",
  paddingRight: "15px",
};

function CookiesConsent() {
  const [showConsentBanner, setShowConsentBanner] = useState(false);
  const [showCookieOptions, setShowCookieOptions] = useState(false);
  const [consentMarketing, setConsentMarketing] = useState(true);

  const httpClient = (body) => {
    fetch(`https://admin.vipa.pt/api/cookie-store`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
  };

  const handleAcceptCookie = () => {
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag("js", new Date());
    gtag("config", "AW-11051167286");
  };

  const isConsent = getCookieConsentValue();
  useEffect(() => {
    if (isConsent === undefined) {
      setShowConsentBanner(true);
    } else if (isConsent) {
      handleAcceptCookie();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {showConsentBanner && (
        <ModalOverlay>
          <ModalContent>
            <ModalTitle>Consentimento de Cookies</ModalTitle>
            <CookieConsent
              onAccept={() => {
                const body = {
                  marketing: consentMarketing,
                };
                httpClient(body);
                setShowConsentBanner(false);
              }}
              onDecline={() => {
                const body = {
                  marketing: false,
                };
                httpClient(body);
                setShowConsentBanner(false);
              }}
              style={{
                marginTop: "30px",
                marginBottom: "10px",
              }}
              buttonText="Aceitar as Cookies"
              declineButtonText="Rejeitar Cookies não essenciais"
              enableDeclineButton
              disableStyles={true}
              buttonStyle={{
                ...buttonStyle,
                marginTop: "20px",
                marginLeft: "15px",
              }}
              ariaAcceptLabel="Aceitar as Cookies"
              ariaDeclineLabel="Rejeitar Cookies não essenciais"
              declineButtonStyle={buttonStyle}
            >
              Nós usamos cookies para melhorar a sua experência de navegação,
              guardar as suas preferências, personalizar o seu conteúdo e para
              analisar o tráfego do nosso website.
            </CookieConsent>
            <button
              onClick={() => {
                setShowCookieOptions(!showCookieOptions);
              }}
              style={buttonStyle}
            >
              Personalizar Cookies
            </button>
            {showCookieOptions && (
              <>
                <CookieOptions
                  style={{
                    marginTop: "15px",
                  }}
                >
                  <Checkbox
                    disabled
                    checked
                    sx={{
                      color: "#59a9ff",
                      "&.Mui-checked": {
                        color: "#59a9ff",
                      },
                    }}
                  />
                  <div style={{ paddingTop: "6px" }}>
                    <p style={{ margin: 0, fontSize: "large" }}>Necessárias</p>
                    <p style={{ margin: 0, fontSize: "small" }}>
                      Estas cookies são necessárias para o funcionamento do
                      website.
                    </p>
                  </div>
                </CookieOptions>
                <CookieOptions
                  style={{
                    marginTop: "10px",
                  }}
                >
                  <Checkbox
                    onChange={() => {
                      setConsentMarketing(!consentMarketing);
                    }}
                    checked={consentMarketing}
                  />
                  <div style={{ paddingTop: "6px" }}>
                    <p style={{ margin: 0, fontSize: "large" }}>Marketing</p>
                    <p style={{ margin: 0, fontSize: "small" }}>
                      Estas cookies servem para analisar e otimizar campanhas
                      publicitárias.
                    </p>
                  </div>
                </CookieOptions>
              </>
            )}
          </ModalContent>
        </ModalOverlay>
      )}
    </>
  );
}

export default CookiesConsent;
