import React, { useEffect, useState } from "react";
import styled from "styled-components";
import jQuery from "jquery";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { ReactComponent as Logo } from "../assets/img/general/seehealth_white.svg";
import { ReactComponent as Logomin } from "../assets/img/general/seehealth_white_min.svg";
import Hamburger from "./hamburger";

const Header = styled.header`
  height: 100px;
  width: 100%;
  background-color: transparent;
  position: fixed;
  z-index: 1000;
  transition: 0.3s;
  animation: NavBarIntro 1.5s 1 2s ease-in-out;
  & svg {
    fill: var(--primary);
  }
  &.scrolled {
    background-color: var(--primary);
    & svg {
      fill: var(--secondary);
    }
  }
`;
const Nav = styled.nav`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  max-width: 1366px;
  margin: 0 auto;
  @media (max-width: 1365px) {
    width: 90%;
  }
  @media (max-width: 1024px) {
    justify-content: space-between;
    padding: 0 1.25rem;
    height: 100px;
    width: 90%;
  }
`;
const Links = styled.a.attrs((props) => ({ className: "item" }))`
  color: var(--primary);
  font-weight: 500;
  text-decoration: none;
  &::after {
    content: " ";
    display: block;
    background-color: #fff;
    height: 5px;
    width: 0;
    transition: 0.3s;
  }
  &.active::after {
    content: " ";
    display: block;
    width: 100%;
  }
`;
const LinkWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.875rem;
  transition: 0.5s ease-out;
  &.scrolled ${Links} {
    color: #7f8080;
    &.active {
      color: #000;
    }
    &::after {
      background-color: #317180;
    }
  }
`;
const Langs = styled.div`
  color: #fff;
  font-weight: 500;
  line-height: 1.875rem;
  cursor: pointer;
  &.active {
    opacity: 1;
    font-weight: 500;
  }
`;
const LangWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 1.875rem;
  width: 210px;
  transition: 0.5s ease-out;
  justify-content: center;
  &.scrolled ${Langs} {
    color: #7f8080;
    &.active {
      color: #000;
    }
  }
  @media (max-width: 1024px) {
    width: unset;
  }
`;

const MobileNav = styled.div`
  background-color: var(--primary);
  position: fixed;
  width: 100%;
  height: 0;
  top: 100px;
  left: 0;
  transition: 0.3s ease-in-out;
  &.scrolled ${Nav} svg {
    fill: var(--secondary);
  }
  &.open {
    display: flex;
    flex-direction: column;
    height: calc(100% - 100px);
    left: 0;
    top: 100px;
    z-index: 1;
    & ${LangWrap} {
      opacity: 1;
      display: flex;
      & ${Langs} {
        color: var(--text);
      }
    }
    & ${LinkWrap} {
      display: flex;
      opacity: 1;
      padding-top: 1.25rem;
      & ${Links} {
        color: var(--text);
      }
    }
  }
  & ${LangWrap} {
    margin-top: auto;
    padding-bottom: 1.25rem;
    justify-content: center;
    opacity: 0;
    display: none;
    & ${Langs} {
      color: var(--text);
    }
  }
  & ${LinkWrap} {
    flex-direction: column;
    opacity: 0;
    display: none;
    & ${Links} {
      color: var(--text);
      padding: 0.625rem 0;
      &::after {
        display: none;
      }
    }
  }
`;
const A = styled.a`
  color: var(--secondary);
  font-weight: 500;
  text-decoration: none;
  position: absolute;
  left: -9999px;
  z-index: 999;
  padding: 1em;
  opacity: 0;
  background-color: black;
  color: white;
  &:focus {
    left: 50%;
    transform: translateX(-50%);
    opacity: 1;
  }
`;

function NavBar() {
  const initialLang = window.localStorage.getItem("i18nextLng");
  const { t, i18n } = useTranslation();
  const [activeLang, setActiveLang] = useState(
    initialLang === "pt-PT"
      ? window.localStorage.setItem("i18nextLng", "pt")
      : window.localStorage.getItem("i18nextLng")
  );
  const isMobile = useMediaQuery({ query: "(max-width: 1024px)" });
  const [open, setOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const changeLanguage = (lng) => {
    setActiveLang(lng);
    i18n.changeLanguage(lng);
  };
  function toggleOpen() {
    setOpen(!open);
    if (open) {
      jQuery("body").css("overflow", "unset");
    } else {
      jQuery("body").css("overflow", "hidden");
    }
  }
  useEffect(() => {
    if (
      jQuery(document.location).attr("pathname").includes("careers") ||
      jQuery(document.location).attr("pathname").includes("privacy")
    ) {
      setScrolled(true);
    }
    jQuery(window).scroll(function (event) {
      event.preventDefault();
      var scroll = jQuery(window).scrollTop();
      if (
        scroll > 20 ||
        jQuery(document.location).attr("pathname").includes("careers") ||
        jQuery(document.location).attr("pathname").includes("privacy")
      ) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    });
    jQuery(document).ready(function () {
      setTimeout(() => {
        var target = jQuery(document.location).attr("hash");
        var offset = -100;
        jQuery("body,html").animate(
          {
            scrollTop: jQuery(target).offset().top - offset,
          },
          700
        );
      }, 2200);
    });
  }, []);
  useEffect(() => {
    if (window.localStorage.getItem("i18nextLng") !== activeLang) {
      changeLanguage(window.localStorage.getItem("i18nextLng"));
    }
    // eslint-disable-next-line
  }, []);
  function toggleClass(e) {
    let newTarget = jQuery(e.target);
    if (!newTarget.hasClass("item")) {
      newTarget = jQuery(e.target).parents(".item");
    }
    if (!newTarget.hasClass("active")) {
      jQuery(".item.active").removeClass("active");
      newTarget.addClass("active");
    }
  }
  const NavBarElementClick = (e) => {
    if (window.location.pathname === "/") {
      e.preventDefault();
      if (isMobile) {
        toggleOpen();
      }
      const id = e.target.getAttribute("href").substring(2);
      const el = document.getElementById(id);
      var headerOffset = 100;
      var elementPosition = el.getBoundingClientRect().top;
      var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
      setTimeout(() => {
        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth",
        });
      }, 100);
    }
  };

  return (
    <Header className={scrolled || open ? "scrolled" : null}>
      <Nav>
        <div>
          <A href="/#content" onClick={NavBarElementClick}>
            {t("accessSkip")}
          </A>
          {isMobile ? (
            <a alt="inicio" href="/">
              <Logomin />
            </a>
          ) : (
            <a title="Página inicial" alt="inicio" href="/">
              <Logo />
            </a>
          )}
        </div>
        {isMobile ? (
          <MobileNav className={open ? "open" : null}>
            <LinkWrap>
              <Links href="/#aboutus" onClick={NavBarElementClick}>
                {t("tab1")}
              </Links>
              <Links href="/#services" onClick={NavBarElementClick}>
                {t("tab2")}
              </Links>
              <Links href="/#projects" onClick={NavBarElementClick}>
                {t("tab3")}
              </Links>
              <Links href="/#careers" onClick={NavBarElementClick}>
                {t("tab4")}
              </Links>
              <Links href="/contacts">{t("tab5")}</Links>
            </LinkWrap>
            <LangWrap>
              <Langs
                className={activeLang === "pt" ? "active" : ""}
                onClick={() => changeLanguage("pt")}
              >
                PT
              </Langs>
              <Langs
                className={activeLang === "en" ? "active" : ""}
                onClick={() => changeLanguage("en")}
              >
                EN
              </Langs>
            </LangWrap>
          </MobileNav>
        ) : (
          <>
            <LinkWrap className={scrolled ? "scrolled" : null}>
              <Links
                href="/#aboutus"
                onClick={(e) => {
                  toggleClass(e);
                  NavBarElementClick(e);
                }}
              >
                {t("tab1")}
              </Links>
              <Links
                href="/#services"
                onClick={(e) => {
                  toggleClass(e);
                  NavBarElementClick(e);
                }}
              >
                {t("tab2")}
              </Links>
              <Links
                href="/#projects"
                onClick={(e) => {
                  toggleClass(e);
                  NavBarElementClick(e);
                }}
              >
                {t("tab3")}
              </Links>
              <Links
                href="/#careers"
                onClick={(e) => {
                  toggleClass(e);
                  NavBarElementClick(e);
                }}
              >
                {t("tab4")}
              </Links>
              <Links href="/contacts">{t("tab5")}</Links>
            </LinkWrap>
            <LangWrap className={scrolled ? "scrolled" : null}>
              <Langs
                className={activeLang === "pt" ? "active" : ""}
                onClick={() => changeLanguage("pt")}
              >
                PT
              </Langs>
              <Langs
                className={activeLang === "en" ? "active" : ""}
                onClick={() => changeLanguage("en")}
              >
                EN
              </Langs>
            </LangWrap>
          </>
        )}

        {isMobile ? (
          <Hamburger
            onClick={toggleOpen}
            className={`${open ? "open" : null} ${
              scrolled ? "scrolled" : null
            }`}
          />
        ) : null}
      </Nav>
    </Header>
  );
}

export default NavBar;
