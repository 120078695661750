import image01 from "../assets/img/general/heart/Main Comp01.png";
import image02 from "../assets/img/general/heart/Main Comp02.png";
import image03 from "../assets/img/general/heart/Main Comp03.png";
import image04 from "../assets/img/general/heart/Main Comp04.png";
import image05 from "../assets/img/general/heart/Main Comp05.png";
import image06 from "../assets/img/general/heart/Main Comp06.png";
import image07 from "../assets/img/general/heart/Main Comp07.png";
import image08 from "../assets/img/general/heart/Main Comp08.png";
import image09 from "../assets/img/general/heart/Main Comp09.png";
import image10 from "../assets/img/general/heart/Main Comp10.png";
import image11 from "../assets/img/general/heart/Main Comp11.png";
import image12 from "../assets/img/general/heart/Main Comp12.png";
import image13 from "../assets/img/general/heart/Main Comp13.png";
import image14 from "../assets/img/general/heart/Main Comp14.png";
import image15 from "../assets/img/general/heart/Main Comp15.png";
import image16 from "../assets/img/general/heart/Main Comp16.png";
import image17 from "../assets/img/general/heart/Main Comp17.png";
import image18 from "../assets/img/general/heart/Main Comp18.png";
import image19 from "../assets/img/general/heart/Main Comp19.png";
import image20 from "../assets/img/general/heart/Main Comp20.png";
import image21 from "../assets/img/general/heart/Main Comp21.png";
import image22 from "../assets/img/general/heart/Main Comp22.png";
import image23 from "../assets/img/general/heart/Main Comp23.png";
import image24 from "../assets/img/general/heart/Main Comp24.png";
import image25 from "../assets/img/general/heart/Main Comp25.png";
import image26 from "../assets/img/general/heart/Main Comp26.png";
import image27 from "../assets/img/general/heart/Main Comp27.png";
import image28 from "../assets/img/general/heart/Main Comp28.png";
import image29 from "../assets/img/general/heart/Main Comp29.png";
const images = {
  image01,
  image02,
  image03,
  image04,
  image05,
  image06,
  image07,
  image08,
  image09,
  image10,
  image11,
  image12,
  image13,
  image14,
  image15,
  image16,
  image17,
  image18,
  image19,
  image20,
  image21,
  image22,
  image23,
  image24,
  image25,
  image26,
  image27,
  image28,
  image29,
};

export default images;
